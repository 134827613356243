import {
  Card,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { t } from "i18next";
import React, { FC } from "react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  FlatCostIndicatorSpecProps,
  StoreAdaptationCostProps,
} from "../../shared/models/adaptationCostModel";
import { NumberFormater } from "../NumberFormater/NumberFormater";
import LightTooltip from "../LightTooltip/LightTooltip";

interface AdaptationCardProps {
  field: FieldArrayWithId<StoreAdaptationCostProps, "adaptationCost", "id">;
  index: number;
  control: Control<StoreAdaptationCostProps>;
  classes: ClassNameMap;
}

const isPriceValid = (costIdicators: FlatCostIndicatorSpecProps[]) => {
  let allModified = true;
  const total: number = costIdicators.reduce((acc, current) => {
    if (
      typeof current.price === "undefined" ||
      typeof current.amount === "undefined"
    ) {
      allModified = false;
    }
    return acc + Number(current.price || 0) * Number(current.amount || 0);
  }, 0);
  return {
    total: total,
    isValid: allModified ? total > 0 : true,
  };
};

const Total = ({
  control,
  adaptationIndex,
}: {
  control: Control<StoreAdaptationCostProps>;
  adaptationIndex: number;
}) => {
  const formValues = useWatch({
    name: `adaptationCost.${adaptationIndex}.constIndicatiors`,
    control,
  });
  const { total, isValid } = isPriceValid(formValues);

  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Typography align="right">
        {t("Total cost for the adaptation practice")}:{" "}
        <NumberFormater value={total} suffix=" zł" />
        {!isValid && (
          <Typography
            color="error"
            variant="caption"
            align="right"
            component="p"
          >
            {" "}
            {t("Complete at least one cost indicator")}
          </Typography>
        )}
      </Typography>
    </Grid>
  );
};

const Cost = ({
  control,
  adaptationIndex,
  constIndicatiorIndex,
  classes,
}: {
  control: Control<StoreAdaptationCostProps>;
  adaptationIndex: number;
  constIndicatiorIndex: number;
  classes: ClassNameMap;
}) => {
  const formValues = useWatch({
    name: `adaptationCost.${adaptationIndex}.constIndicatiors.${constIndicatiorIndex}`,
    control,
  });
  const total: number =
    Number(formValues.price || 0) * Number(formValues.amount || 0);

  return (
    <Grid item xs={12}>
      <Typography className={classes.typography} align="right">
        <NumberFormater value={total} suffix=" zł" />
      </Typography>
    </Grid>
  );
};

const AdaptationCard: FC<AdaptationCardProps> = ({
  field,
  index,
  control,
  classes,
}) => {
  const additionalAdaptationCostsWithValue =
    field.additionalAdaptationCosts.filter((aa) => !!Number(aa.cost));
  const additionalAdaptationCostsWithoutValue =
    field.additionalAdaptationCosts.filter((aa) => !Number(aa.cost));
  const adaptationBenefitsWithValue = field.adaptationBenefits.filter(
    (ab) => !!Number(ab.cost)
  );
  const adaptationBenefitsWithoutValue = field.adaptationBenefits.filter(
    (ab) => !Number(ab.cost)
  );

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  return (
    <Card variant="outlined" square>
      <CardContent>
        <Grid container spacing={2}>
          {field.practiceLabel !== "" && (
            <Grid item xs={12}>
              <InputLabel shrink>{t("Practice")}</InputLabel>
              <Typography
                component="h2"
                variant="h5"
                className={classes.typography}
              >
                {field.practiceLabel}
              </Typography>
            </Grid>
          )}
          {!!field.practiceDescription && (
            <Grid item xs={12}>
              <InputLabel shrink>{t("Description")}</InputLabel>
              <Typography className={classes.typography}>
                {field.practiceDescription}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <InputLabel shrink>{t("Action")}</InputLabel>
            <Typography className={classes.typography}>
              {field.actionLabel}
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography component="h3" variant="h6">
                {t("Estimated costs over a 30-year period:")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined" square>
                <CardContent>
                  <Grid container spacing={2}>
                    {field.constIndicatiors.map((constIndicatior, cIndex) => {
                      return (
                        <Grid
                          container
                          item
                          key={constIndicatior.id}
                          spacing={2}
                        >
                          <Grid item xs={6} md={6}>
                            <InputLabel shrink>
                              {t("Cost indicator")}
                            </InputLabel>
                            <Typography className={classes.typography}>
                              {constIndicatior.description === "" ? (
                                constIndicatior.label
                              ) : (
                                <LightTooltip
                                  title={constIndicatior.description}
                                >
                                  <span>{constIndicatior.label}</span>
                                </LightTooltip>
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            md={2}
                            alignItems="flex-end"
                            container
                          >
                            <Controller
                              name={`adaptationCost.${index}.constIndicatiors.${cIndex}.amount`}
                              control={control}
                              rules={{
                                required: t("The field is required").toString(),
                                validate: {
                                  positive: (v) => {
                                    if (field.constIndicatiors.length === 1) {
                                      return (
                                        (v && v > 0) ||
                                        t(
                                          "Value must be greater than 0"
                                        ).toString()
                                      );
                                    } else {
                                      return (
                                        isPriceValid(field.constIndicatiors)
                                          .isValid || ""
                                      );
                                    }
                                  },
                                },
                              }}
                              render={({
                                field: { ref, ...rest },
                                fieldState: { error, invalid },
                              }) => {
                                return (
                                  <TextField
                                    {...rest}
                                    error={invalid}
                                    key={field.id}
                                    label={t("Amount")}
                                    inputRef={ref}
                                    InputProps={{
                                      inputComponent: NumberFormater as any,
                                      inputProps: {
                                        decimalScale: 0,
                                      },
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {constIndicatior.label
                                            .split("[")[1]
                                            ?.split("]")[0] || "szt."}
                                        </InputAdornment>
                                      ),
                                    }}
                                    helperText={error?.message}
                                  />
                                );
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            md={2}
                            alignItems="flex-end"
                            container
                          >
                            <Controller
                              name={`adaptationCost.${index}.constIndicatiors.${cIndex}.price`}
                              control={control}
                              rules={{
                                required: t("The field is required").toString(),
                                validate: {
                                  positive: (v) => {
                                    if (field.constIndicatiors.length === 1) {
                                      return (
                                        (v && v > 0) ||
                                        t(
                                          "Value must be greater than 0"
                                        ).toString()
                                      );
                                    } else {
                                      return (
                                        isPriceValid(field.constIndicatiors)
                                          .isValid || ""
                                      );
                                    }
                                  },
                                },
                              }}
                              render={({
                                field: { ref, ...rest },
                                fieldState: { error, invalid },
                              }) => {
                                return (
                                  <TextField
                                    {...rest}
                                    error={invalid}
                                    key={field.id}
                                    label={t("Estimated unit price")}
                                    inputRef={ref}
                                    InputProps={{
                                      inputComponent: NumberFormater as any,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          zł
                                        </InputAdornment>
                                      ),
                                    }}
                                    helperText={error?.message}
                                  />
                                );
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            md={2}
                            alignItems="flex-end"
                            container
                          >
                            <Grid item xs={12}>
                              <InputLabel shrink>{t("Cost")}</InputLabel>
                              <Cost
                                adaptationIndex={index}
                                constIndicatiorIndex={cIndex}
                                control={control}
                                classes={classes}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Total control={control} adaptationIndex={index} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {(!!field.additionalAdaptationCosts.length ||
          !!field.adaptationBenefits.length) && (
          <>
            <Divider className={classes.hr} />
            <Grid container spacing={2}>
              <Grid
                container
                item
                xs={12}
                md
                spacing={2}
                className={classes.flexStart}
              >
                {!!adaptationBenefitsWithValue.length && (
                    <Grid item xs={12}>
                      <Typography
                        component="h3"
                        variant="h6"
                        className={classes.typography}
                      >
                        {t("Adaptation benefits")}
                      </Typography>
                    </Grid>
                  ) &&
                  adaptationBenefitsWithValue.map((aa) => (
                    <Grid container item xs={12} key={aa.id} spacing={2}>
                      <Grid item xs={6}>
                        <Typography>{aa.label}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right">
                          <NumberFormater value={aa.cost} suffix=" zł" />
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                <Grid item xs={6}>
                  {adaptationBenefitsWithValue.length > 0 ? (
                    <Typography className={classes.typography}>
                      {t("Additional benefits")}
                    </Typography>
                  ) : (
                    <Typography
                      component="h3"
                      variant="h6"
                      className={classes.typography}
                    >
                      {t("Additional benefits")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.typography} component="ul">
                    {adaptationBenefitsWithoutValue.length ? (
                      adaptationBenefitsWithoutValue.map((aa, i) => (
                        <Typography
                          component="li"
                          key={aa.id}
                          className={classes.typography}
                        >
                          {aa.label}
                        </Typography>
                      ))
                    ) : (
                      <Typography component="li" className={classes.typography}>
                        {t("not observed")}
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {!hidden && (
                <Divider orientation="vertical" variant="middle" flexItem />
              )}
              <Grid
                container
                item
                xs={12}
                md
                spacing={2}
                className={classes.flexStart}
              >
                {!!additionalAdaptationCostsWithValue.length && (
                    <Grid item xs={12}>
                      <Typography
                        component="h3"
                        variant="h6"
                        className={classes.typography}
                      >
                        {t("Adaptation costs")}
                      </Typography>
                    </Grid>
                  ) &&
                  additionalAdaptationCostsWithValue.map((aa) => (
                    <React.Fragment key={aa.id}>
                      <Grid item xs={6}>
                        <Typography>{aa.label}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right">
                          <NumberFormater value={aa.cost} suffix=" zł" />
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                <Grid item xs={6}>
                  {additionalAdaptationCostsWithValue.length > 0 ? (
                    <Typography className={classes.typography}>
                      {t("Other adaptation costs")}
                    </Typography>
                  ) : (
                    <Typography
                      component="h3"
                      variant="h6"
                      className={classes.typography}
                    >
                      {t("Other adaptation costs")}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.typography} component="ul">
                    {additionalAdaptationCostsWithoutValue.length ? (
                      additionalAdaptationCostsWithoutValue.map((aa, i) => (
                        <Typography
                          component="li"
                          key={aa.id}
                          className={classes.typography}
                        >
                          {aa.label}
                        </Typography>
                      ))
                    ) : (
                      <Typography component="li" className={classes.typography}>
                        {t("not observed")}
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default AdaptationCard;
