import React, { FC } from "react";
import Grid from "@material-ui/core/Grid";
import { FormStepper } from "../components/Steps/FormStepper";

export const CreateReport: FC = () => {
  return (
    <Grid container spacing={3}>
      <FormStepper />
    </Grid>
  );
};
