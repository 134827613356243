import React, { createContext, FC, useContext, useEffect } from "react";
import { useState } from "react";
import { useAdaptationCostsContext } from "../api/AdaptationCostProvider";
import { StoreAdaptationCostProps } from "../models/adaptationCostModel";
import { useAdaptationStore } from "./AdaptationStore";

interface AdaptationCostStoreProps {
  adaptationCost: StoreAdaptationCostProps;
  setAdaptationCost?: React.Dispatch<
    React.SetStateAction<StoreAdaptationCostProps>
  >;
}

const initContext: AdaptationCostStoreProps = {
  adaptationCost: {
    adaptationCost: [],
    financingSource: {},
  },
};
const initStore: StoreAdaptationCostProps = {
  adaptationCost: [],
  financingSource: {},
};

const AdaptationCostStore =
  createContext<AdaptationCostStoreProps>(initContext);

export const useAdaptationCostStore = () => {
  const adaptationCostStore = useContext(AdaptationCostStore);
  if (!adaptationCostStore) {
    throw new Error(
      "useAdaptationCostStore must be used within the AdaptationCostStore.Provider"
    );
  }
  return adaptationCostStore;
};

export const AdaptationCostStoreProvider: FC = ({ children }) => {
  const [adaptationCost, setAdaptationCost] =
    useState<StoreAdaptationCostProps>(initStore);
  const { adaptationCosts } = useAdaptationCostsContext();
  const { adaptation } = useAdaptationStore();
  const value = {
    adaptationCost: adaptationCost,
    setAdaptationCost: setAdaptationCost,
  } as AdaptationCostStoreProps;

  useEffect(() => {
    if (adaptationCost.adaptationCost.length) setAdaptationCost(initStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adaptationCosts, adaptation]);

  return (
    <AdaptationCostStore.Provider value={value}>
      {children}
    </AdaptationCostStore.Provider>
  );
};
