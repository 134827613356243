import axios from "axios";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAdaptationStore } from "../stores/AdaptationStore";
import {
  FlatThreatDescriptionProps,
  ThreatDescriptionProps,
} from "../models/threatModel";

const qs = require("qs");

const cancelTokenSource = axios.CancelToken.source();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

type Status = "init" | "loading" | "fetched" | "error";
type Action = "fetch" | "fetching" | "done";

interface NoAdaptationsContextProps {
  status: Status;
  threats: Array<FlatThreatDescriptionProps>;
  setAction?: (value: React.SetStateAction<Action>) => void;
}

const adaptationsContextDefaultValue: NoAdaptationsContextProps = {
  status: "init",
  threats: [],
};

const NoAdaptationsContext = createContext<NoAdaptationsContextProps>(
  adaptationsContextDefaultValue
);

const useNoAdaptationsContextValue = (): NoAdaptationsContextProps => {
  const [noAdaptationsStateValue, setNoAdaptationsStateValue] = useState<
    Array<FlatThreatDescriptionProps>
  >([]);
  const [status, setStatus] = useState<Status>("init");
  const [action, setAction] = useState<Action>("done");
  const { adaptation } = useAdaptationStore();
  const cache = useRef<{
    [key: string]: {
      noAdaptations: FlatThreatDescriptionProps[];
    };
  }>({});
  useEffect(() => {
    if (action === "fetch") {
      setAction("fetching");
      if (status === "loading") cancelTokenSource.cancel();

      const noAdaptation = adaptation.filter((a) => !!a.noAdaptation);
      if (noAdaptation.length) {
        setStatus("loading");
        const threatIds = [...new Set(noAdaptation.map((m) => m.threatId))];
        const threatIdsStr = threatIds.join(",");
        const fetchNoAdaptations = async () => {
          if (cache.current[threatIdsStr]) {
            const data = cache.current[threatIdsStr];
            setNoAdaptationsStateValue(data.noAdaptations);
            setStatus("fetched");
          } else {
            try {
              const query = qs.stringify(
                {
                  populate: "*",
                  filters: {
                    threat: {
                      id: {
                        $in: threatIds,
                      },
                    },
                  },
                },
                {
                  encodeValuesOnly: true, // prettify URL
                }
              );
              const response = await instance.get(`no-adaptations?${query}`, {
                cancelToken: cancelTokenSource.token,
              });
              const noAdaptationsData = response.data.data.reduce(
                (
                  acc: FlatThreatDescriptionProps[],
                  curr: ThreatDescriptionProps
                ) => {
                  if (
                    acc.find((a) => a.id === curr.attributes.threat.data.id) ===
                    undefined
                  ) {
                    acc.push({
                      id: curr.attributes.threat.data.id,
                      label: curr.attributes.threat.data.attributes.label,
                      noAdaptationDesc: [],
                    });
                  }
                  const tIndex = acc.findIndex(
                    (a) => a.id === curr.attributes.threat.data.id
                  );
                  acc[tIndex].noAdaptationDesc.push({
                    sectorLabel: curr.attributes.sector.data.attributes.label,
                    description: curr.attributes.description,
                  });
                  return acc;
                },
                []
              );
              setNoAdaptationsStateValue(noAdaptationsData);
              cache.current[threatIdsStr] = {
                noAdaptations: noAdaptationsData,
              }; // set response in cache;

              setStatus("fetched");
            } catch (error) {
              console.error(error);
              setStatus("error");
            }
            setAction("done");
          }
        };
        fetchNoAdaptations();
      } else {
        setNoAdaptationsStateValue([]);
        setStatus("init");
        setAction("done");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    if (adaptation.length) {
      setNoAdaptationsStateValue([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adaptation]);

  return {
    threats: noAdaptationsStateValue,
    status,
    setAction,
  };
};

export const useNoAdaptationsContext = () => {
  const adaptationsContext = useContext(NoAdaptationsContext);
  if (!NoAdaptationsContext) {
    throw new Error(
      "useNoAdaptationsContext must be used within the NoAdaptationsContext.Provider"
    );
  }
  return adaptationsContext;
};

export const NoAdaptationsProvider: FC = ({ children }) => (
  <NoAdaptationsContext.Provider value={useNoAdaptationsContextValue()}>
    {children}
  </NoAdaptationsContext.Provider>
);
