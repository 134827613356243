import axios from "axios";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FlatHistoryProps, HistoryProps } from "../models/historyModel";
import { useCommuneStore } from "../stores/CommuneStore";
import { convertStrapiResponse } from "../models/strapiResponseModel";
const qs = require("qs");

const cancelTokenSource = axios.CancelToken.source();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

type Status = "init" | "loading" | "fetched" | "error";

interface HistoriesContextProps {
  histories: Array<FlatHistoryProps>;
  status: Status;
}

const historiesContextDefaultValue: HistoriesContextProps = {
  histories: [],
  status: "init",
};

const HistoriesContext = createContext<HistoriesContextProps>(
  historiesContextDefaultValue
);

const useHistoriesContextValue = (): HistoriesContextProps => {
  const [histories, setHistories] = useState<Array<FlatHistoryProps>>([]);
  const [status, setStatus] = useState<Status>("init");
  const { commune } = useCommuneStore();
  const cache = useRef<{ [key: string]: FlatHistoryProps[] }>({});

  useEffect(() => {
    if (status === "loading") cancelTokenSource.cancel();

    if (commune) {
      setStatus("loading");
      const fetchHistories = async () => {
        if (cache.current[commune.id]) {
          const data = cache.current[commune.id];
          setHistories(data);
          setStatus("fetched");
        } else {
          try {
            const query = qs.stringify(
              {
                populate: '*',
                filters: {
                  commune: {
                    id: {
                      $eq: commune.id,
                    },
                  },
                },
              },
              {
                encodeValuesOnly: true, // prettify URL
              }
            );
            const response = await instance.get(`historical-losses?${query}`, {
              cancelToken: cancelTokenSource.token,
            });
            const data = convertStrapiResponse<HistoryProps>(
              response.data.data
            );
            const histories = data.map(
              (h: HistoryProps): FlatHistoryProps => ({
                id: h.id,
                cost: h.cost,
                year: h.year,
                label: h.sector.data.attributes.label,
              })
            );
            setHistories(histories);
            cache.current[commune.id] = histories; // set response in cache;
            setStatus("fetched");
          } catch (error) {
            console.error(error);
            setStatus("error");
          }
        }
      };
      fetchHistories();
    } else {
      setHistories([]);
      setStatus("init");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commune]);

  return {
    histories,
    status,
  };
};

export const useHistoriesContext = () => {
  const historiesContext = useContext(HistoriesContext);
  if (!HistoriesContext) {
    throw new Error(
      "useHistoriesContext must be used within the HistoriesContext.Provider"
    );
  }
  return historiesContext;
};

export const HistoriesProvider: FC = ({ children }) => (
  <HistoriesContext.Provider value={useHistoriesContextValue()}>
    {children}
  </HistoriesContext.Provider>
);
