import axios from "axios";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SectorProps } from "../models/sectorModel";
import {
  convertStrapiResponse,
} from "../models/strapiResponseModel";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

type status = "init" | "loading" | "fetched" | "error";

interface SectorsContextProps {
  sectors: Array<SectorProps>;
  status: status;
}

const sectorsContextDefaultValue: SectorsContextProps = {
  sectors: [],
  status: "init",
};

const SectorsContext = createContext<SectorsContextProps>(
  sectorsContextDefaultValue
);

const useSectorsContextValue = (): SectorsContextProps => {
  const [sectors, setSectors] = useState<Array<SectorProps>>([]);
  const [status, setStatus] = useState<status>("init");
  const cache = useRef<SectorProps[]>();

  useEffect(() => {
    if (status === "init") {
      const fetchSectors = async () => {
        if (cache.current) {
          const data = cache.current;
          setSectors(data);
          setStatus("fetched");
        } else {
          setStatus("loading");
          try {
            const response = await instance.get("sectors");
            const data = convertStrapiResponse<SectorProps>(response.data.data);
            setSectors(data);
            setStatus("fetched");
          } catch (error) {
            console.error(error);
            setStatus("error");
          }
        }
      };
      fetchSectors();
    }
  }, [status]);

  return {
    sectors,
    status,
  };
};

export const useSectorsContext = () => {
  const sectorsContext = useContext(SectorsContext);
  if (!SectorsContext) {
    throw new Error(
      "useSectorsContext must be used within the SectorsContext.Provider"
    );
  }
  return sectorsContext;
};

export const SectorsProvider: FC = ({ children }) => {
  const { sectors, status } = useSectorsContextValue();
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { sectors, status } as SectorsContextProps;
  return (
    <SectorsContext.Provider value={value}>{children}</SectorsContext.Provider>
  );
};
