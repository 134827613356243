import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

interface NumberFormaterProps extends NumberFormatProps {
  inputRef?: (instance: HTMLInputElement | null) => void;
  onChange?: (event: { target: { name: string; value: string } }) => void;
  name?: string;
}

export const NumberFormater: React.FC<NumberFormaterProps> = ({
  inputRef,
  onChange,
  ...other
}) => {
  return (
    <NumberFormat
      decimalScale={2}
      isNumericString
      thousandSeparator=" "
      decimalSeparator=","
      allowNegative={false}
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange &&
          other.name &&
          onChange({
            target: {
              name: other.name,
              value: values.value,
            },
          });
      }}
      displayType={inputRef && onChange ? "input" : "text"}
    />
  );
};
