import React, { FC, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { useCommunesContext } from "../../shared/api/CommuneProvider";
import { useCommuneStore } from "../../shared/stores/CommuneStore";
import { CommuneProps } from "../../shared/models/communeModel";
import { useTranslation } from "react-i18next";

interface Option {
  value: any;
  label: string;
}

export const CommuneSelect: FC = () => {
  const { communes, status } = useCommunesContext();
  const { commune: selectedCommune, setCommune: setSelectedCommune } =
    useCommuneStore();
  const { t } = useTranslation();
  useEffect(() => {}, [status, selectedCommune]);

  return (
    <Autocomplete
      id="commune-select"
      options={communes as Option[]}
      loading={status !== "fetched"}
      value={selectedCommune || null}
      onChange={(e, value) => {
        setSelectedCommune && setSelectedCommune(value as CommuneProps);
      }}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label={t("Commune")} variant="outlined" />
      )}
    />
  );
};
