import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

const resources = {
  en: {
    translation: {
      adaptations5plus: "adaptations",
    },
  },
  "pl-PL": {
    translation: {
      Options: "Opcje",
      Close: "Zamknij",
      Select: "Wybierz",
      "Create report": "Utwórz raport",
      "Language switcher": "Przełącznik języków",
      Commune: "Gmina",
      "Costs and benefits of adaptation activities":
        "Koszty i korzyści z działań adaptacyjnych",
      "Selected commune": "Wybrana gmina",
      Risks: "Ryzyka",
      "Risk analysis results": "Wyniki analizy ryzyka",
      "Selected threats from the list of threats identified for the unit":
        "Wybrane zagrożenia z listy zagrożeń identyfikowanych dla jednostki",
      "Sensitive sectors": "Sektory wrażliwe",
      "no adaptations selected": "brak wybranych adaptacji",
      "not observed": "nie zaobserwowano",
      "Suggested actions and adaptation practices":
        "Proponowane działania i praktyki adaptacyjne",
      Threat: "Zagrożenie",
      Threats: "Zagrożenia",
      Sector: "Sektor",
      Cost: "Koszt",
      Costs: "Koszty",
      Year: "Rok",
      "The cost of non-adaptation": "Koszty braku adaptacji",
      "possible losses in the 30-year analysis period [until 2050]":
        "Prawdopodobne straty w okresie analizy 30 lat (do 2050 roku)",
      "Selected adaptation activities and practices":
        "Wybrane działania i praktyki adaptacyjne",
      "Details of selected adaptation practices":
        "Szczegóły wybranych praktyk adaptacyjnych",
      "Adaptive action": "Działanie adaptacyjne",
      "Adaptation practice": "Praktyka adaptacyjna",
      "Selected adaptation practice": "Wybrana praktyka adaptacyjna",
      "Estimated costs of adaptation practices":
        "Szacowane koszty praktyk adaptacyjnych",
      "Funding sources for adaptation practices":
        "Źródła finansowania praktyk adaptacyjnych",
      "Own funds": "Środki własne",
      "Co-financing": "Dofinansowanie",
      Action: "Działanie",
      "Action name": "Nazwa działania",
      Benefit: "Korzyść",
      Benefits: "Korzyści",
      "Additional benefits": "Korzyści dodatkowe",
      "Estimated cost": "Szacowany koszt",
      "Estimated cost of practice": "Szacowany koszt praktyki",
      Indicator: "Wskaźnik",
      "Adaptation costs": "Koszty adaptacji",
      "Other adaptation costs": "Pozostałe koszty adaptacji",
      Description: "Opis",
      Value: "Wartość",
      Price: "Cena",
      "Estimated unit price": "Cena jednostkowa",
      Amount: "Ilość",
      "Estimated adaptation practices costs": "Szacowane koszty praktyk",
      Sum: "Suma",
      "Adaptation benefits": "Korzyści z adaptacji",
      "Other adaptation benefits": "Pozostałe korzyści z adaptacji",
      "Economic value of investments: ENPV (benefits from adaptation less adaptation costs)":
        "Ekonomiczna wartość inwestycji: wskaźnik ENPV (korzyści z adaptacji pomniejszone o koszty adaptacji)",
      "The Economic Net Present Value (ENPV) is positive, which means that the benefits outweigh the costs of adaptation activities.":
        "Ekonomiczna zaktualizowana wartość netto (ENPV) jest dodatnia, oznacza to przewagę korzyści nad kosztami z działań adaptacyjnych.",
      "The Economic Net Present Value (ENPV) is negative, which means the cost advantage over the benefits of implementing adaptation activities.":
        "Ekonomiczna zaktualizowana wartość netto (ENPV) jest ujemna, oznacza to przewagę kosztów nad korzyściami z realizacji działań adaptacyjnych.",
      "Check all adaptation practices":
        "Zaznacz wszystkie praktyki adaptacyjne",
      Choose: "Wybierz",
      Selected: "Wybrano",
      "No adaptation was selected": "Wybrano brak adaptacji",
      adaptation: "adaptacja",
      adaptations: "adaptacje",
      adaptations5plus: "adaptacji",
      Data: "Dane",
      "Select all threats": "Zaznacz wszystkie zagrożenia",
      "Please enter the name of the commune to which the analysis is to be carried out":
        "Proszę wpisać nazwę gminy, której ma dotyczyć analiza",
      "A threat list retrieval error has occurred":
        "Wystąpił błąd w trakcie pobierania listy zagrożeń",
      "You have to choose an adaptation for each threat":
        "Musisz wybrać adaptację dla każdego zagrożenia",
      "No adaptation": "Brak adaptacji",
      "I do not plan to implement adaptation activities and practices":
        "Nie planuję realizacji działań  i praktyk adaptacyjnych",
      "Please select the Threat from the list":
        "Proszę wybrać Zagrożenie z listy",
      "Adaptation choice": "Wybór adaptacji",
      "Cost estimation": "Szacowanie kosztów",
      "The costs of non-adaptation are difficult to estimate on the basis of the provided statistics":
        "Koszty braku adaptacji trudne do wyceny na podstawie przekazanych statystyk",
      Summary: "Podsumowanie",
      "Please choose one of the options": "Proszę wybrać jedną z opcji",
      Correct: "Popraw",
      Back: "Wstecz",
      Generate: "Generuj",
      Next: "Przejdź dalej",
      Cancel: "Anuluj",
      "Sum of the costs of adaptation practices":
        "Suma kosztów praktyk adaptacyjnych",
      "Financing can not be greater than 100%":
        "Finansowanie nie może być większe niż 100 %",
      "Financing cannot be less than 100%":
        "Finansowanie nie może być mniejsze niż 100 %",
      Practice: "Praktyka",
      "Practice name": "Nazwa praktyki",
      "Practice description": "Opis praktyki",
      "Cost-bearing entity": "Podmiot ponoszący koszty",
      "Cost indicator": "Wskaźnik kosztowy",
      "Unit cost": "Koszt jednostkowy",
      "The field is required": "To pole jest wymagane",
      "Value must be greater than 0": "Wartość musi być większa niż 0",
      "Number of units": "Liczba jednostek",
      "I do not plan to implement adaptation activities and practices for a given threat":
        "Nie planuję realizacji działań i praktyk adaptacyjnych dla danego zagrożenia",
      "No adaptation to threats": "Brak adaptacji dla zagrożeń",
      "Cost of non-adaptation": "Koszt braku adaptacji",
      "Estimated costs over a 30-year period:":
        "Szacunkowe koszty w okresie 30 lat:",
      "Total cost for the adaptation practice": "Suma kosztów dla praktyki",
      "In the absence of adaptation in the future, the likely losses resulting from changes in the level of risk associated with climate change may amount to":
        "W przypadku braku adaptacji w przyszłości prawdopodobne  straty wynikające ze zmian poziomu ryzyka związanego ze zmianami klimatu mogą wynieść",
      Loading: "Wczytywanie",
      "Financing source": "Źródło finansowania",
      "Commune expenses": "Wydatki gminy",
      "Maximum 100%": "Maksymalnie 100%",
      "Historical loss": "Strata historyczna",
      "Historical losses": "Straty historyczne",
      "No historical losses": "Brak strat historycznych",
      "Loss type": "Rodzaj straty",
      "Economic Net Present Value (ENPV) is positive, which means that the benefits outweigh the costs of implementing the practice.":
        "Ekonomiczna zaktualizowana wartość netto (ENPV) jest dodatnia, oznacza to przewagę korzyści nad kosztami z realizacji praktyki.",
      "Economic Net Present Value (ENPV) is negative, which means that the costs are ahead of the benefits of implementing the practice.":
        "Ekonomiczna zaktualizowana wartość netto (ENPV) jest ujemna, oznacza to przewagę kosztów nad korzyściami z realizacji praktyki.",
      "The results of the analysis are illustrative and may help in the initial diagnosis of the needs related to the implementation of adaptation investments.":
        "Wyniki analizy mają charakter poglądowy i mogą pomóc we wstępnym rozpoznaniu w zakresie potrzeb związanych z realizacją inwestycji adaptacyjnych.",
      "Low impact": "Niski wpływ",
      "Medium impact": "Średni wpływ",
      "High impact": "Wysoki wpływ",
      "Very low": "Bardzo niskie",
      Low: "Niskie",
      Medium: "Średnie",
      High: "Wysokie",
      "Very high": "Bardzo wysokie",
      "Does not concern": "Nie dotyczy",
      "Data not available": "Brak danych",
      "Cost-benefit calculator of the implementation of adaptation measures by municipalities":
        "Kalkulator kosztów i korzyści z realizacji działań adaptacyjnych przez gminy (KiK Adapt)",
      "Complete at least one cost indicator":
        "Wypełnij co najmniej jeden wskaźnik kosztowy",
      "There are errors in the form": "Formularz zawiera błędy",
      "Loading...": "Ładowanie...",
      "Download PDF": "Pobierz PDF",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: DETECTION_OPTIONS,
    resources,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "pl-PL",
  });

export default i18n;
