import { createMuiTheme } from "@material-ui/core/styles";
import { plPL } from "@material-ui/core/locale";

import { Overrides as CoreOverrides } from "@material-ui/core/styles/overrides";
import { AlertClassKey } from "@material-ui/lab";
import { CSSProperties } from "@material-ui/styles";

interface Overrides extends CoreOverrides {
  // Define additional lab components here as needed....
  MuiAlert?:
    | Partial<Record<AlertClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
}

const overrides: Overrides = {
  // Style sheet name ⚛️
  MuiOutlinedInput: {
    // Name of the rule
    root: {
      // Some CSS
      borderRadius: 0,
    },
  },
  MuiButton: {
    root: {
      borderRadius: 0,
    },
    contained: {
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  MuiPaper: {
    outlined: {
      boxShadow: "none",
      border: "1px solid #e5e5e5",
    },
  },
  MuiAlert: {
    root: {
      borderRadius: 0,
    },
  },
  MuiGrid: {
    root: {
      "& iframe": {
        border: "none",
      },
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: "none",
    },
  },
  MuiTableRow: {
    root: {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      "tbody &:last-of-type": {
        borderBottomColor: "transparent",
      },
    },
  },
};

export const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: "#0D6376",
      },
      secondary: {
        main: "#00988F",
      },
    },
    typography: {
      fontFamily:
        "Lato,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
      h1: {
        fontSize: "2.5rem",
        marginBottom: "0.5rem",
        fontWeight: 500,
        lineHeight: 1.2,
      },
      h2: {
        fontSize: "2rem",
        marginBottom: "0.5rem",
        fontWeight: 500,
        lineHeight: 1.2,
      },
      h3: {
        fontSize: "1.75rem",
        marginBottom: "0.5rem",
        fontWeight: 500,
        lineHeight: 1.2,
      },
      h4: {
        fontSize: "1.5rem",
        marginBottom: "0.5rem",
        fontWeight: 500,
        lineHeight: 1.2,
      },
      h5: {
        fontSize: "1.25rem",
        marginBottom: "0.5rem",
        fontWeight: 500,
        lineHeight: 1.2,
      },
      h6: {
        fontSize: "1rem",
        marginBottom: "0.5rem",
        fontWeight: 500,
        lineHeight: 1.2,
      },
      body1: {
        color: "#212529",
      },
    },
    overrides: overrides,
  },
  plPL
);
