import axios from "axios";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useAdaptationStore } from "../stores/AdaptationStore";
import { BcrBenefitsProps } from "../models/bcrBenefitsModel";
import { useCommuneStore } from "../stores/CommuneStore";

const qs = require("qs");

const cancelTokenSource = axios.CancelToken.source();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

type Status = "init" | "loading" | "fetched" | "error";
type Action = "fetch" | "fetching" | "done";

interface BcrBenefitsContextProps {
  bcrBenefits: Array<BcrBenefitsProps>;
  status: Status;
  setAction?: (value: React.SetStateAction<Action>) => void;
}

const bcrBenefitsContextDefaultValue: BcrBenefitsContextProps = {
  bcrBenefits: [],
  status: "init",
};

const BcrBenefitsContext = createContext<BcrBenefitsContextProps>(
  bcrBenefitsContextDefaultValue
);

const useBcrBenefitsContextValue = (): BcrBenefitsContextProps => {
  const [bcrBenefits, setBcrBenefits] = useState<Array<BcrBenefitsProps>>([]);
  const [status, setStatus] = useState<Status>("init");
  const [action, setAction] = useState<Action>("done");
  const { adaptation } = useAdaptationStore();
  const { commune } = useCommuneStore();
  const cache = useRef<{
    [key: string]: BcrBenefitsProps[];
  }>({});

  useEffect(() => {
    // console.log("useEffect bcrBenefits", action);
    if (action === "fetch") {
      setAction("fetching");
      if (status === "loading") {
        cancelTokenSource.cancel();
      }
      const filteredAdaptation = adaptation.filter((a) => !a.noAdaptation);
      if (filteredAdaptation.length && commune) {
        setStatus("loading");
        const bcrBenefitsIds = filteredAdaptation.map((a) => ({
          sectorId: a.sectorId,
          threatId: a.threatId,
        }));
        const bcrBenefitsIdsString = bcrBenefitsIds
          .map((b) => commune.id + "-" + b.sectorId + "-" + b.threatId)
          .join(",");
        const fetchBcrBenefits = async () => {
          if (cache.current[bcrBenefitsIdsString]) {
            const data = cache.current[bcrBenefitsIdsString];
            setBcrBenefits(data);
            setStatus("fetched");
          } else {
            try {
              const query = qs.stringify(
                {
                  populate: "*",
                  filters: {
                    $or: bcrBenefitsIds.map((b) => ({
                      $and: [
                        {
                          commune: { id: commune.id },
                        },
                        {
                          sector: {
                            id: b.sectorId,
                          },
                        },
                        {
                          threat: {
                            id: b.threatId,
                          },
                        },
                      ],
                    })),
                  },
                },
                {
                  encodeValuesOnly: true, // prettify URL
                }
              );
              const response = await instance.get(`bcr-benefits?${query}`, {
                cancelToken: cancelTokenSource.token,
              });
              setBcrBenefits(response.data.data);

              cache.current[bcrBenefitsIdsString] = response.data.data;
              setStatus("fetched");
            } catch (error) {
              if (axios.isCancel(error)) {
                console.log("cancelled");
              } else {
                setStatus("error");
              }
            }
            setAction("done");
          }
        };
        fetchBcrBenefits();
      } else {
        setBcrBenefits([]);
        setStatus("init");
        setAction("done");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    // console.log("useEffect bcrBenefits adaptation", adaptation);
    if (adaptation.length && bcrBenefits.length) {
      setBcrBenefits([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adaptation]);

  return {
    bcrBenefits,
    status,
    setAction,
  };
};

export const useBcrBenefitsContext = (): BcrBenefitsContextProps => {
  const context = useContext(BcrBenefitsContext);
  if (context === undefined) {
    throw new Error(
      "useBcrBenefitsContext must be used within a BcrBenefitsContext.Provider"
    );
  }
  return context;
};

export const BcrBenefitsProvider: FC = ({ children }) => (
  <BcrBenefitsContext.Provider value={useBcrBenefitsContextValue()}>
    {children}
  </BcrBenefitsContext.Provider>
);
