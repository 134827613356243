export interface StrapiResponseProps<T> {
  id: number;
  attributes: Omit<T, "id">;
}

export const convertStrapiResponse = <T>(
  data: Array<StrapiResponseProps<T>>
) => {
  return data.map((item) => {
    return {
      ...item.attributes,
      id: item.id,
    };
  });
};
