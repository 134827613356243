import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { FirstStep } from "./FirstStep";
import { Card, CardActions, CardContent, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { SecondStep } from "./SecondStep";
import { AlertSnackbar } from "../AlertSnackbar/AlertSnackbar";
import { LastStep } from "./LastStep";
import { useTranslation } from "react-i18next";
import { useCommuneStore } from "../../shared/stores/CommuneStore";
import ankieta from "../../assets/images/ankieta.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    container: {
      marginBottom: theme.spacing(4),
    },
    card: {
      minWidth: 275,
      maxWidth: 800,
    },
  })
);

export interface StepContentProps {
  isValidCallback: (value: boolean, alert?: string) => void;
}

export interface StepContentHandle {
  submit?: () => void;
  postSubmit?: () => void;
}

function getStepContent(
  step: number,
  isValid: StepContentProps["isValidCallback"],
  ref: React.ForwardedRef<StepContentHandle>
) {
  switch (step) {
    case 0:
      return <FirstStep isValidCallback={isValid} ref={ref} />;
    case 1:
      return <SecondStep isValidCallback={isValid} ref={ref} />;
    case 2:
      return <LastStep isValidCallback={isValid} ref={ref} />;
    default:
      return "Unknown step";
  }
}

export const FormStepper = React.memo(() => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [valid, setValid] = React.useState(false);
  const [alert, setAlert] = React.useState("Proszę wybrać którąś z opcji");
  const steps = [t("Adaptation choice"), t("Cost estimation"), t("Summary")];
  const { commune, setCommune } = useCommuneStore();

  type AlertSnackbarHandle = React.ElementRef<typeof AlertSnackbar>;

  const alertRef = React.useRef<AlertSnackbarHandle>(null);
  const stepRef = React.useRef<StepContentHandle>(null);

  const handleNext = () => {
    stepRef.current?.submit && stepRef.current.submit();

    if (valid) {
      (() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setValid(false);
        return stepRef.current?.postSubmit && stepRef.current.postSubmit();
      })();
    } else alertRef?.current?.show();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setCommune && setCommune(null);
    setActiveStep(0);
  };

  const isValid = React.useCallback(
    (value: boolean, alert: string = t("There are errors in the form")) => {
      setValid(value);
      setAlert(alert);
    },
    [t]
  );

  return (
    <>
      <Grid item xs={12}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item xs={12} container>
        {activeStep === steps.length ? (
          <Grid item xs={12} container justify="center">
            <Card className={classes.card} variant="outlined" square>
              <CardContent>
                <Typography
                  variant="h4"
                  component="h1"
                  paragraph
                  align="center">
                  Dziękujemy Państwu za skorzystanie z kalkulatora Kosztów i
                  Korzyści z Działań Adaptacyjnych.
                </Typography>
                <Typography
                  variant="h4"
                  component="h2"
                  paragraph
                  align="center">
                  Mamy nadzieję, że wyniki pomogą w lepszym planowaniu adaptacji
                  do zmian klimatu.
                </Typography>
                <Grid
                  item
                  xs={12}
                  container
                  justify="center"
                  alignItems="center"
                  style={{ marginBottom: "2rem" }}>
                  <Grid item xs={12} sm={6}>
                    <Typography paragraph align="center">
                      Zapraszamy do przekazania informacji zwrotnej, aby
                      ulepszyć to narzędzie w przyszłości:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography paragraph align="center">
                      <img
                        src={ankieta}
                        alt="Ankieta"
                        style={{ width: "100%", maxWidth: "200px" }}
                      />
                    </Typography>
                    <Typography paragraph align="center">
                      <a
                        href="https://ankiety.ios.edu.pl/index.php/494941?lang=pl"
                        target="blank">
                        Opinia o kalkulatorze Kosztów i Korzyści z Działań
                        Adaptacyjnych (KiK Adapt) (ios.edu.pl)
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
                <Typography paragraph align="center">
                  Wszelkie Państwa uwagi będą dla nas bardzo cenne!
                  <br />
                  Zespół IOŚ-PIB
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  disabled={activeStep === 0}
                  variant="contained"
                  onClick={handleBack}
                  className={classes.button}>
                  {t("Correct")}
                </Button>
                <Button
                  onClick={handleReset}
                  className={classes.button}
                  variant="contained"
                  color="primary">
                  Nowy
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} className={classes.container}>
              {getStepContent(activeStep, isValid, stepRef)}
            </Grid>
            <Grid item xs={6}>
              <Typography align="left">
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="/">
                  {t("Cancel")}
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}>
                  {t("Back")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}>
                  {t("Next")}
                </Button>
              </Typography>
            </Grid>
            <AlertSnackbar severity="error" text={alert} ref={alertRef} />
          </>
        )}
      </Grid>
    </>
  );
});
