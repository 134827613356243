import React, { FC } from "react";
import { CommuneSelect } from "../components/CommuneSelect/CommuneSelect";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HistoryTable from "../components/Tables/HistoryTable";
import MatrixTable from "../components/Tables/MatrixTable";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useMatrixStore } from "../shared/stores/MatrixStore";
import { useAdaptationsContext } from "../shared/api/AdaptationProvider";
import { useTranslation } from "react-i18next";
import LightTooltip from "../components/LightTooltip/LightTooltip";

export const History: FC = () => {
  const { matrix } = useMatrixStore();
  const disabled = !matrix.length;
  const { setAction } = useAdaptationsContext();
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Typography variant="h2" component="h1" paragraph>
          {t(
            "Cost-benefit calculator of the implementation of adaptation measures by municipalities"
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography paragraph>
          Kalkulator kosztów i korzyści jest narzędziem edukacyjnym skierowanym
          do przedstawicieli jednostek samorządu terytorialnego. Zadaniem
          kalkulatora jest ukazanie możliwych do osiągnięcia korzyści z
          adaptacji w gminie. Kalkulator pomaga przeanalizować również możliwe
          koszty realizacji działań adaptacyjnych. Wyniki analizy mają charakter
          poglądowy i mogą stanowić wstępne rozpoznanie w zakresie potrzeb
          związanych z realizacją inwestycji w wybranej gminie.
        </Typography>
        <Typography paragraph>
          Kolejne kroki pozwalają na pozyskanie wiedzy o tym:
        </Typography>
        <Typography paragraph component="ul">
          <li>
            jakie straty wystąpiły w Państwa gminie na przestrzeni ostatnich{" "}
            <LightTooltip title="Statystyki zostały udostępnione przez urzędy wojewódzkie, dotyczą rolnictwa i infrastruktury komunalnej.">
              <span>20 lat</span>
            </LightTooltip>
            ,
          </li>
          <li>
            jaki poziom ryzyka związanego ze zmianami klimatu może wystąpić w
            Państwa gminie,
          </li>
          <li>
            jakie potencjalne korzyści są możliwe do uzyskania dzięki realizacji
            działań adaptacyjnych.
          </li>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CommuneSelect />
      </Grid>
      <Grid item xs={12}>
        <HistoryTable />
      </Grid>
      <Grid item xs={12}>
        <MatrixTable />
      </Grid>
      <Grid item xs={12}>
        <Typography align="right">
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/create-report"
            disabled={disabled}
            onClick={() => {
              setAction && setAction("fetch");
            }}
          >
            {t("Next")}
          </Button>
        </Typography>
      </Grid>
    </Grid>
  );
};
