import axios from "axios";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FlatMatrixProps, MatrixProps } from "../models/matrixModel";
import { FlatRiskProps, RiskProps } from "../models/riskModel";
import { useCommuneStore } from "../stores/CommuneStore";
import { convertStrapiResponse } from "../models/strapiResponseModel";
const qs = require("qs");

const cancelTokenSource = axios.CancelToken.source();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

type Status = "init" | "loading" | "fetched" | "error";

interface MatricesContextProps {
  matrices: Array<FlatMatrixProps>;
  status: Status;
}

const matricesContextDefaultValue: MatricesContextProps = {
  matrices: [],
  status: "init",
};

const MatricesContext = createContext<MatricesContextProps>(
  matricesContextDefaultValue
);

const useMatricesContextValue = (): MatricesContextProps => {
  const [matrices, setMatrices] = useState<Array<FlatMatrixProps>>([]);
  const [status, setStatus] = useState<Status>("init");
  const { commune } = useCommuneStore();
  const cache = useRef<{ [key: string]: FlatMatrixProps[] }>({});

  useEffect(() => {
    if (status === "loading") cancelTokenSource.cancel();

    if (commune) {
      setStatus("loading");
      const fetchMatrices = async () => {
        if (cache.current[commune.id]) {
          const data = cache.current[commune.id];
          setMatrices(data);
          setStatus("fetched");
        } else {
          try {
            const query = qs.stringify(
              {
                populate: ["threat", "risks.sector"],
                filters: {
                  commune: {
                    id: {
                      $eq: commune.id,
                    },
                  },
                },
              },
              {
                encodeValuesOnly: true, // prettify URL
              }
            );
            const response = await instance.get(`matrices?${query}`, {
              cancelToken: cancelTokenSource.token,
            });
            const data = convertStrapiResponse<MatrixProps>(response.data.data);
            const matrices = data.map((m: MatrixProps): FlatMatrixProps => {
              return {
                value: m.id,
                threatId: m.threat.data.id,
                noAdaptationCost: m.no_adaptation_cost,
                noAdaptationDescription: m.threat.data.attributes.description,
                label: m.threat.data.attributes.label,
                risks: convertStrapiResponse(m.risks.data).map(
                  (r: RiskProps): FlatRiskProps => ({
                    value: r.id,
                    riskValue: r.risk_value,
                    label: r.sector.data.attributes.label,
                    sectorId: r.sector.data.id,
                  })
                ),
              };
            });
            setMatrices(matrices);
            cache.current[commune.id] = matrices; // set response in cache;
            setStatus("fetched");
          } catch (error) {
            console.error(error);
            setStatus("error");
          }
        }
      };
      fetchMatrices();
    } else {
      setMatrices([]);
      setStatus("init");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commune]);

  return {
    matrices,
    status,
  };
};

export const useMatricesContext = () => {
  const matricesContext = useContext(MatricesContext);
  if (!MatricesContext) {
    throw new Error(
      "useMatricesContext must be used within the MatricesContext.Provider"
    );
  }
  return matricesContext;
};

export const MatricesProvider: FC = ({ children }) => (
  <MatricesContext.Provider value={useMatricesContextValue()}>
    {children}
  </MatricesContext.Provider>
);
