import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      paddingBlock: theme.spacing(2),
      paddingInline: theme.spacing(3),
      maxWidth: "400px",
      color: theme.palette.common.black,
      fontSize: theme.typography.body2.fontSize,
      fontFamily: theme.typography.body2.fontFamily,
      fontWeight: theme.typography.body2.fontWeight
    },
    childStyle: {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      cursor: "pointer",
    },
  })
);

const LightTooltip: React.FC<TooltipProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} {...rest}>
      {React.cloneElement(children, { className: classes.childStyle })}
    </Tooltip>
  );
};

export default LightTooltip;
