import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useMatrixStore } from "../../shared/stores/MatrixStore";
import { CommuneSelect } from "../CommuneSelect/CommuneSelect";
import { ThreatSelect } from "../ThreatSelect/ThreatSelect";
import { AdaptationTable } from "../Tables/AdaptationTable";
import AppBar from "@material-ui/core/AppBar";
// import { SectorPills } from "../SectorPills/SectorPills";
import { Alert } from "@material-ui/lab";
import { useAdaptationsContext } from "../../shared/api/AdaptationProvider";
import { useAdaptationStore } from "../../shared/stores/AdaptationStore";
import { StepContentHandle, StepContentProps } from "./FormStepper";
import { useAdaptationCostsContext } from "../../shared/api/AdaptationCostProvider";
import { useTranslation } from "react-i18next";
import { useNoAdaptationsContext } from "../../shared/api/NoAdaptationProvider";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.invalid": {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  })
);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`threat-tabpanel-${index}`}
      aria-labelledby={`threat-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `threat-tab-${index}`,
    "aria-controls": `threat-tabpanel-${index}`,
  };
}

export const FirstStep = React.forwardRef<StepContentHandle, StepContentProps>(
  ({ isValidCallback }, ref) => {
    const [value, setValue] = React.useState(0);
    const { matrix } = useMatrixStore();
    const { adaptations } = useAdaptationsContext();
    const { adaptation: selected } = useAdaptationStore();
    const { setAction } = useAdaptationCostsContext();
    const { setAction: setNoAdaptationsAction } = useNoAdaptationsContext();
    const { t } = useTranslation();
    const tabsRefs = useRef<Array<HTMLDivElement | null>>([]);

    React.useImperativeHandle(ref, () => ({
      postSubmit() {
        setAction && setAction("fetch");
        setNoAdaptationsAction && setNoAdaptationsAction("fetch");
      },
      submit() {
        matrix.forEach((m) => {
          if (selected.findIndex((s) => m.threatId === s.threatId) === -1) {
            tabsRefs.current[m.value]?.classList.add("invalid");
            tabsRefs.current[m.value]?.focus();
          } else tabsRefs.current[m.value]?.classList.remove("invalid");
        });
      },
    }));

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    };

    useEffect(() => {
      setValue(0);
    }, [matrix]);

    useEffect(() => {
      let haveSelected = true;
      matrix.forEach((m) => {
        if (selected.findIndex((s) => m.threatId === s.threatId) === -1) {
          haveSelected = false;
        }
      });
      isValidCallback(
        !!selected.length && haveSelected,
        t("You have to choose an adaptation for each threat")
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const DisplayTabs = () => {
      const classes = useStyles();
      return (
        <>
          <AppBar position="static" elevation={3} color="transparent">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="threat-tabs"
              variant="fullWidth">
              {matrix.map((m, i) => (
                <Tab
                  ref={(el) => (tabsRefs.current[m.value] = el)}
                  label={m.label}
                  {...a11yProps(i)}
                  key={m.value}
                  classes={{ root: classes.root, selected: classes.selected }}
                />
              ))}
            </Tabs>
          </AppBar>
          {matrix.map((m, i) => (
            <TabPanel value={value} index={i} key={m.value}>
              <AdaptationTable
                rows={[
                  ...adaptations.filter((a) => a.threatId === m.threatId),
                  {
                    id: -m.threatId,
                    actionId: -1,
                    actionLabel: t(
                      "I do not plan to implement adaptation activities and practices for a given threat"
                    ),
                    practiceDescription: "",
                    threatId: m.threatId,
                    practiceLabel: "",
                    noAdaptation: true,
                    noAdaptationDescription: m.noAdaptationDescription,
                  },
                ]}
                selected={selected.filter((a) => a.threatId === m.threatId)}
              />
            </TabPanel>
          ))}
        </>
      );
    };

    return (
      <Grid container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <CommuneSelect />
        </Grid>
        <Grid item md={8} sm={6} xs={12}>
          <ThreatSelect />
        </Grid>
        {/* <Grid item xs={12}>
          <SectorPills />
        </Grid> */}
        <Grid item xs={12}>
          {!!matrix.length ? (
            <DisplayTabs />
          ) : (
            <Alert severity="info">
              {t("Please select the Threat from the list")}
            </Alert>
          )}
        </Grid>
      </Grid>
    );
  }
);
