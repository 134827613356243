import { createContext, FC, useContext, useEffect, useReducer } from "react";
import { useAdaptationsContext } from "../api/AdaptationProvider";
import { FlatAdaptationProps } from "../models/adaptationModel";

type State = {
  selected: FlatAdaptationProps[];
};

const initialState: State = {
  selected: [],
};

type Action = {
  type: "Add" | "Remove" | "Clear";
  payload: FlatAdaptationProps[];
};

const adaptationReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "Add":
      const newSelected = [...state.selected];
      action.payload.forEach((p) => {
        if (state.selected.findIndex((s) => s.id === p.id) === -1) {
          newSelected.push(p);
        }
      });
      return { selected: newSelected };
    case "Remove":
      return {
        selected: state.selected.filter((s) => {
          if (action.payload.findIndex((p) => s.id === p.id) !== -1) {
            return false;
          }
          return true;
        }),
      };
    case "Clear":
      return { selected: [] };
    default:
      return state;
  }
};

interface AdaptationStoreProps {
  adaptation: Array<FlatAdaptationProps>;
  addAdaptation: (adaptations: FlatAdaptationProps[]) => any;
  removeAdaptation: (adaptations: FlatAdaptationProps[]) => any;
}

const initialValues: AdaptationStoreProps = {
  adaptation: [],
  addAdaptation: (adaptations: FlatAdaptationProps[]) => {},
  removeAdaptation: (adaptations: FlatAdaptationProps[]) => {},
};

const AdaptationStore = createContext<AdaptationStoreProps>(initialValues);

export const useAdaptationStore = () => {
  const adaptationStore = useContext(AdaptationStore);
  if (!adaptationStore) {
    throw new Error(
      "useAdaptationStore must be used within the AdaptationStore.Provider"
    );
  }
  return adaptationStore;
};

export const AdaptationStoreProvider: FC = ({ children }) => {
  const [adaptation, dispatch] = useReducer(adaptationReducer, initialState);
  const { adaptations } = useAdaptationsContext();
  const value = {
    adaptation: adaptation.selected,
    addAdaptation: (adaptations: FlatAdaptationProps[]) =>
      dispatch({ type: "Add", payload: adaptations }),
    removeAdaptation: (adaptations: FlatAdaptationProps[]) =>
      dispatch({ type: "Remove", payload: adaptations }),
  } as AdaptationStoreProps;

  useEffect(() => {
    if (adaptation.selected.length) dispatch({ type: "Clear", payload: [] });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adaptations]);

  return (
    <AdaptationStore.Provider value={value}>
      {children}
    </AdaptationStore.Provider>
  );
};
