import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { SnackbarContentProps } from "@material-ui/core";
import { AlertProps } from "@material-ui/lab";

interface AlertSnackbarProps extends SnackbarContentProps {
  text: string;
  severity: AlertProps["severity"];
}

interface AlertSnackbarHandle {
  show: () => void;
}

export const AlertSnackbar = React.forwardRef<
  AlertSnackbarHandle,
  AlertSnackbarProps
>(({ text, severity }, ref) => {
  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    show() {
      setOpen(true);
    },
  }));

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert severity={severity} variant="filled">
        {text}
      </Alert>
    </Snackbar>
  );
});
