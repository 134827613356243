import { Button } from "@material-ui/core";
import { usePDF } from "@react-pdf/renderer";
import { t } from "i18next";
import React, { useEffect } from "react";

interface DownloadPDFProps {
  document: any;
}

export const DownloadPDF: React.FC<DownloadPDFProps> = ({ document }) => {
  const [pdf, update] = usePDF({ document: document });

  useEffect(() => {
    update();
  }, [pdf, update, document]);
  return (
    <React.Fragment>
      {!pdf.loading && pdf.url && <iframe src={pdf.url + '#toolbar=1'} title="pdf" style={{width: '100%', height: 800}}/>}
      <Button
        variant="contained"
        color="primary"
        href={pdf.url || ""}
        disabled={pdf.loading}
        download="document.pdf"
      >
        {pdf.loading ? t("Loading...") : t("Download PDF")}
      </Button>
    </React.Fragment>
  );
};
