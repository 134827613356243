import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistoriesContext } from "../../shared/api/HistoryProvider";
import { Alert, Skeleton } from "@material-ui/lab";
import { FlatHistoryProps } from "../../shared/models/historyModel";
import { NumberFormater } from "../NumberFormater/NumberFormater";
import { useTranslation } from "react-i18next";
import { useSectorsContext } from "../../shared/api/SectorProvider";
import { SectorProps } from "../../shared/models/sectorModel";
import { Typography } from "@material-ui/core";

interface HeadCell {
  disablePadding?: boolean;
  id: keyof FlatHistoryProps;
  label: string;
  numeric: boolean;
  align?: "left" | "right" | "center";
}

let headCells: HeadCell[] = [];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

const getHistoryRow = (rows: FlatHistoryProps[], sector: SectorProps) => {
  const filteredRows = rows.filter((r) => r.label === sector.label);
  return filteredRows.length === 0 ? (
    <TableRow hover key={`history-table-${sector.id}`}>
      <TableCell component="th" scope="row">
        {sector.label}
      </TableCell>
      <TableCell align="center">2001 - 2019</TableCell>
      <TableCell align="right">
        brak wyceny w przekazanych statystykach
      </TableCell>
    </TableRow>
  ) : (
    filteredRows.map((row) => (
      <TableRow hover key={`history-table-${row.id}`}>
        <TableCell component="th" scope="row">
          {row.label}
        </TableCell>
        <TableCell align="center">{row.year}</TableCell>
        <TableCell align="right">
          <NumberFormater value={row.cost} suffix=" zł" />
        </TableCell>
      </TableRow>
    ))
  );
};

export default function HistoryTable() {
  const classes = useStyles();
  const { histories, status } = useHistoriesContext();
  const { t } = useTranslation();
  const { sectors } = useSectorsContext();

  headCells = [
    {
      id: "label",
      numeric: false,
      label: t("Loss type"),
    },
    {
      id: "year",
      numeric: true,
      label: t("Year"),
      align: "center",
    },
    {
      id: "cost",
      numeric: true,
      label: t("Historical loss"),
      align: "right",
    },
  ];

  return (
    <div className={classes.root}>
      {status === "init" && (
        <Alert severity="info">
          {t(
            "Please enter the name of the commune to which the analysis is to be carried out"
          )}
        </Alert>
      )}
      {status === "error" && (
        <Alert severity="error">
          {t("A threat list retrieval error has occurred")}
        </Alert>
      )}
      {(status === "loading" || status === "fetched") && (
        <>
          <Paper className={classes.paper} square variant="outlined">
            <TableContainer>
              <Table
                className={classes.table}
                size="medium"
                aria-label="history table"
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.align}>
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {status === "loading" &&
                    [...Array(5)].map((e, i) => (
                      <TableRow key={i}>
                        <TableCell colSpan={headCells.length + 1}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      </TableRow>
                    ))}

                  {status === "fetched" &&
                    sectors
                      .filter((s) => s.is_historical)
                      .map((sf) => getHistoryRow(histories, sf))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Typography variant="caption" component="p" display="block">
            <strong>Rolnictwo:</strong> szkody w uprawach rolnych, straty
            zwierząt, szkody w sadach, plantacjach roślin wieloletnich,
            zniszczenia środków trwałych (w tym maszyn, urządzeń, ciągników oraz
            samochodów).
          </Typography>
          <Typography variant="caption" paragraph>
            <strong>Infrastruktura komunalna:</strong> uszkodzenia dróg, mostów,
            zniszczenia sieci kanalizacji sanitarnej, deszczowej, wodociągowej,
            szkody w stacjach uzdatniania wody, oczyszczalniach ścieków,
            uszkodzone budynki komunalne, szkoły, budowle piętrzące,
            regulacyjne, stacje pomp, rowy odwadniające stanowiące własność
            jednostek samorządu terytorialnego.
          </Typography>
        </>
      )}
    </div>
  );
}
