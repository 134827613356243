import React from "react";
import {
  useForm,
  SubmitHandler,
  Control,
  useWatch,
  useFieldArray,
} from "react-hook-form";
import { Grid, Typography, Theme } from "@material-ui/core";
import { StepContentHandle, StepContentProps } from "./FormStepper";
import { useAdaptationCostStore } from "../../shared/stores/AdaptationCostStore";
import { useAdaptationCostsContext } from "../../shared/api/AdaptationCostProvider";
import { useBcrBenefitsContext } from "../../shared/api/BrcBenefitsProvider";
import { NumberFormater } from "../NumberFormater/NumberFormater";
import { createStyles, makeStyles } from "@material-ui/styles";
import {
  FlatCostIndicatorSpecProps,
  NoAdaptationCostProps,
  StoreAdaptationCostProps,
} from "../../shared/models/adaptationCostModel";
import { useAdaptationStore } from "../../shared/stores/AdaptationStore";
import { useMatrixStore } from "../../shared/stores/MatrixStore";
import { useTranslation } from "react-i18next";
import AdaptationCard from "../AdaptationCard/AdaptationCard";
import FinancingSourceCard from "../FinancingSourceCard/FinancingSourceCard";
import NoAdaptationCard from "../NoAdaptationCard/NoAdaptationCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      marginTop: "0.25rem",
    },
    hr: {
      marginBlock: theme.spacing(4),
    },
    flexStart: {
      alignContent: "flex-start",
    },
  })
);

const Total = ({ control }: { control: Control<StoreAdaptationCostProps> }) => {
  const formValues = useWatch({
    name: "adaptationCost",
    control,
  });
  const total: number = formValues.reduce(
    (acc, current) =>
      acc +
      Number(
        current.constIndicatiors.reduce(
          (accI, currentI: FlatCostIndicatorSpecProps) =>
            accI + Number(currentI.price || 0) * Number(currentI.amount || 0),
          0
        ) || 0
      ),
    0
  );
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Typography align="right">
        {t("Sum of the costs of adaptation practices")}:{" "}
        <NumberFormater value={total} suffix=" zł" />
      </Typography>
    </Grid>
  );
};

export const SecondStep = React.forwardRef<StepContentHandle, StepContentProps>(
  ({ isValidCallback }, ref) => {
    const classes = useStyles();
    const formRef = React.useRef<HTMLFormElement>(null);
    const { adaptationCosts } = useAdaptationCostsContext();
    const { adaptationCost, setAdaptationCost } = useAdaptationCostStore();
    const { adaptation } = useAdaptationStore();
    const { matrix } = useMatrixStore();
    const { t } = useTranslation();
    const { setAction } = useBcrBenefitsContext();

    const noAdaptations: NoAdaptationCostProps[] = adaptation
      .filter((a) => a.noAdaptation)
      .map((a) => {
        const matrixItem =
          matrix[matrix.findIndex((m) => a.threatId === m.threatId)];
        return {
          id: a.threatId,
          threatLabel: matrixItem.label,
          threatId: matrixItem.threatId,
          cost: matrixItem.noAdaptationCost,
          noAdaptationDescription: matrixItem.noAdaptationDescription,
        };
      });

    const defaultValues: StoreAdaptationCostProps = adaptationCost
      .adaptationCost.length
      ? adaptationCost
      : {
          adaptationCost: adaptationCosts,
          financingSource: {},
        };
    const {
      handleSubmit,
      control,
      formState: { isValid, dirtyFields },
      reset,
      getValues,
      setValue,
    } = useForm<StoreAdaptationCostProps>({
      defaultValues: defaultValues,
      mode: "onBlur",
    });
    const { fields } = useFieldArray({
      name: "adaptationCost",
      control,
    });
    React.useImperativeHandle(ref, () => ({
      submit() {
        formRef.current?.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      },
      postSubmit() {
        setAction && setAction("fetch");
      },
    }));

    React.useEffect(() => {
      isValidCallback(isValid);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    React.useEffect(() => {
      reset({ adaptationCost: adaptationCosts });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adaptationCosts]);

    React.useEffect(() => {
      if (adaptationCost.adaptationCost.length) reset(adaptationCost);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adaptationCost]);

    const onSubmit: SubmitHandler<StoreAdaptationCostProps> = (data) => {
      // console.log(data); //TODO save financing + remove log
      setAdaptationCost && setAdaptationCost(data);
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Grid container spacing={4} justify="center">
          {!!fields.length && (
            <>
              {fields.map((field, index) => {
                return (
                  <Grid item xs={12} key={field.id}>
                    <AdaptationCard
                      field={field}
                      index={index}
                      control={control}
                      classes={classes}
                    />
                  </Grid>
                );
                // TODO: dodać Skeleton
              })}
              <Total control={control} />
            </>
          )}
          {!!noAdaptations.length &&
            noAdaptations.map((a, index) => (
              <Grid item xs={12} key={a.id}>
                <NoAdaptationCard field={a} classes={classes} />
              </Grid>
            ))}
          {!fields.length && !noAdaptations.length && (
            <Grid item>{t("Loading")}...</Grid>
          )}
          {!!fields.length && (
            <Grid item xs={4}>
              <FinancingSourceCard
                control={control}
                dirtyFields={dirtyFields}
                getValues={getValues}
                classes={classes}
                setValue={setValue}
              />
            </Grid>
          )}
        </Grid>
      </form>
    );
  }
);
