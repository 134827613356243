import React, { FC } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Chip,
  Avatar,
  ChipProps,
} from "@material-ui/core";
import { LightenDarkenColor } from "../../shared/utils/colors";
const { contrastColor } = require("contrast-color");

interface PillProps extends ChipProps {
  bgColor: string;
  label?: string;
  avatarText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
      color: ({ bgColor }: PillProps) => contrastColor({ bgColor: bgColor }),
      backgroundColor: ({ bgColor }: PillProps) => bgColor,
    },
    avatar: {
      backgroundColor: ({ bgColor }: PillProps) =>
        LightenDarkenColor(bgColor, -20),
    },
    text: {
      color: ({ bgColor }: PillProps) =>
        contrastColor({ bgColor: LightenDarkenColor(bgColor, -20) }),
    },
  })
);

export const Pill: FC<PillProps> = (props) => {
  const classes = useStyles(props);
  const { bgColor, label, avatarText, ...rest } = props;
  return (
    <Chip
      {...rest}
      avatar={
        avatarText ? (
          <Avatar className={classes.avatar}>
            <div className={classes.text}>{props.avatarText}</div>
          </Avatar>
        ) : undefined
      }
      label={props.label}
      className={classes.chip}
    />
  );
};
