import React from "react";
import {
  createStyles,
  makeStyles,
  TableRow,
  TableRowProps,
} from "@material-ui/core";
import clsx from "clsx";
import { Theme } from "@material-ui/core/styles";
import { LightenDarkenColor } from "../../shared/utils/colors";
const { contrastColor } = require("contrast-color");

interface ColoredRowProps extends TableRowProps {
  sectorColor: string;
}

const useStyles = makeStyles<Theme, ColoredRowProps>((theme: Theme) =>
  createStyles({
    row: {
      position: "relative",
      "&:before": {
        position: "absolute",
        content: "''",
        borderColor: "transparent",
        borderStyle: "solid",
        borderWidth: "0.25rem",
        borderRightColor: ({ sectorColor }) =>
          LightenDarkenColor(sectorColor, -40),
        left: "calc(-0.5rem - 1px)",
        top: "1.25rem",
        height: 0,
        width: 0,
      },
      "&:after": {
        content: ({ title }) => `"${title ? title : ""}"`,
        display: "block",
        fontSize: "0.5rem",
        padding: "0.25rem",
        fontWeight: 900,
        position: "absolute",
        top: "0.5rem",
        color: ({ sectorColor }) => contrastColor({ bgColor: sectorColor }),
        left: "calc(-0.25rem - 1px)",
        width: "10rem",
        textTransform: "uppercase",
        backgroundColor: ({ sectorColor }) => sectorColor,
        boxShadow: ({ sectorColor }) =>
          sectorColor === "transparent"
            ? "none"
            : "1px 1px 2px 0px rgba(0, 0, 0, 0.3)",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  })
);

export const ColoredRow: React.FC<ColoredRowProps> = (props) => {
  const classes = useStyles(props);
  const { sectorColor, className, ...other } = props;
  return <TableRow className={clsx(classes.row, className)} {...other} />;
};
