import React from "react";
import { StepContentHandle, StepContentProps } from "./FormStepper";
import {
  AdaptationCostsListProps,
  BenefitListProps,
  DocumentPdf,
  PdfDocumentProps,
} from "../DocumentPDF/DocumentPDF";
import { useCommuneStore } from "../../shared/stores/CommuneStore";
import { useMatrixStore } from "../../shared/stores/MatrixStore";
import { useAdaptationCostStore } from "../../shared/stores/AdaptationCostStore";
import { useAdaptationStore } from "../../shared/stores/AdaptationStore";
import { NoAdaptationCostProps } from "../../shared/models/adaptationCostModel";
import { DownloadPDF } from "../DocumentPDF/DownloadPDF";
import { useHistoriesContext } from "../../shared/api/HistoryProvider";
import { useBcrBenefitsContext } from "../../shared/api/BrcBenefitsProvider";

export const LastStep = React.forwardRef<StepContentHandle, StepContentProps>(
  ({ isValidCallback }, ref) => {
    React.useImperativeHandle(ref, () => ({
      postSubmit() {
        //
      },
    }));
    React.useEffect(() => {
      isValidCallback(true);
    }, [isValidCallback]);

    const { histories } = useHistoriesContext();
    const { commune } = useCommuneStore();
    const { matrix } = useMatrixStore();
    const { adaptation } = useAdaptationStore();
    const { adaptationCost } = useAdaptationCostStore();
    const { bcrBenefits } = useBcrBenefitsContext();

    const noAdaptations: NoAdaptationCostProps[] = adaptation
      .filter((a) => !!a.noAdaptation)
      .map((a) => {
        const matrixItem =
          matrix[matrix.findIndex((m) => a.threatId === m.threatId)];
        return {
          id: a.threatId,
          threatId: matrixItem.threatId,
          threatLabel: matrixItem.label,
          cost: matrixItem.noAdaptationCost,
        };
      });

    console.log(adaptationCost, bcrBenefits);
    const benefits: BenefitListProps[] = Object.values(
      adaptationCost.adaptationCost.reduce<{ [key: string]: BenefitListProps }>(
        (acc, curr) => {
          curr.adaptationBenefits.forEach((benefit) => {
            if (acc[benefit.benefitId.toString()]) {
              acc[benefit.benefitId.toString()].cost +=
                Number(benefit.cost) || 0;
            } else {
              acc[benefit.benefitId.toString()] = {
                ...benefit,
                cost: Number(benefit.cost) || 0,
              };
            }
          });
          return acc;
        },
        {}
      )
    ).filter((b) => b.cost > 0);

    const adaptationCostsList: AdaptationCostsListProps[] = Object.values(
      adaptationCost.adaptationCost.reduce<{
        [key: string]: AdaptationCostsListProps;
      }>((acc, curr) => {
        curr.additionalAdaptationCosts.forEach((cost) => {
          if (acc[cost.additionalCostId.toString()]) {
            acc[cost.additionalCostId.toString()].cost +=
              Number(cost.cost) || 0;
          } else {
            acc[cost.additionalCostId.toString()] = {
              ...cost,
              cost: Number(cost.cost) || 0,
            };
          }
        });
        return acc;
      }, {})
    ).filter((a) => a.cost > 0);

    const data: PdfDocumentProps = {
      commune: commune?.label || "",
      history: histories,
      matrix: matrix,
      adaptation: adaptation,
      adaptationCosts: adaptationCost.adaptationCost,
      noAdaptations: noAdaptations,
      benefitsList: benefits,
      adaptationCostsList: adaptationCostsList,
      financingSource: adaptationCost.financingSource,
      bcrBenefitsSum: bcrBenefits.reduce<number>((acc, curr) => {
        const reduction = adaptationCost.adaptationCost
          .filter(
            (a) =>
              a.threatId === curr.attributes.threat.data.id &&
              a.sectorId === curr.attributes.sector.data.id
          )
          .reduce<number>((acc, curr) => {
            return (
              acc +
              curr.constIndicatiors.reduce<number>((accCi, currCi) => {
                return (
                  accCi +
                  Number(currCi.amount || 0) * Number(curr.reductionFactor || 1)
                );
              }, 0)
            );
          }, 0);

        return (
          acc + Number(curr.attributes.value) * (reduction > 1 ? 1 : reduction)
        );
      }, 0),
    };
    return <DownloadPDF document={<DocumentPdf {...data} />} />;
  }
);
