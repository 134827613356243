import axios from "axios";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FlatAdaptationProps,
  AdaptationProps,
} from "../models/adaptationModel";
import { useMatrixStore } from "../stores/MatrixStore";
import { convertStrapiResponse } from "../models/strapiResponseModel";

const qs = require("qs");

const cancelTokenSource = axios.CancelToken.source();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

type Status = "init" | "loading" | "fetched" | "error";
type Action = "fetch" | "fetching" | "done";

interface AdaptationsContextProps {
  adaptations: Array<FlatAdaptationProps>;
  status: Status;
  sectorsCount: Array<number>;
  setAction?: (value: React.SetStateAction<Action>) => void;
}

const adaptationsContextDefaultValue: AdaptationsContextProps = {
  adaptations: [],
  sectorsCount: [],
  status: "init",
};

const AdaptationsContext = createContext<AdaptationsContextProps>(
  adaptationsContextDefaultValue
);

const useAdaptationsContextValue = (): AdaptationsContextProps => {
  const [adaptations, setAdaptations] = useState<Array<FlatAdaptationProps>>(
    []
  );
  const [status, setStatus] = useState<Status>("init");
  const [action, setAction] = useState<Action>("done");
  const { matrix } = useMatrixStore();
  const cache = useRef<{
    [key: string]: {
      adaptation: FlatAdaptationProps[];
      sectorsCount: Array<number>;
    };
  }>({});
  const [sectorsCount, setSectorsCount] = useState<Array<number>>([]);

  useEffect(() => {
    if (action === "fetch") {
      setAction("fetching");
      if (status === "loading") cancelTokenSource.cancel();

      if (matrix.length) {
        setStatus("loading");
        const threatIds = matrix.map((m) => ({
          threatId: m.threatId,
          sectorId:
            m.risks.filter((r) => r.riskValue > 0)?.map((r) => r.sectorId) ||
            [],
        }));
        const threatIdsStr = threatIds
          .map((t) => `t_${t.threatId}s_${t.sectorId.join(".")}`)
          .join(",");
        const fetchAdaptations = async () => {
          if (cache.current[threatIdsStr]) {
            const data = cache.current[threatIdsStr];
            setAdaptations(data.adaptation);
            setSectorsCount(data.sectorsCount);
            setStatus("fetched");
          } else {
            try {
              const filters = threatIds.map((t) => ({
                $and: [
                  {
                    threat: {
                      id: t.threatId,
                    },
                  },
                  {
                    sector: {
                      id: {
                        $in: t.sectorId,
                      },
                    },
                  },
                ],
              }));

              const query = qs.stringify(
                {
                  populate: "*",
                  filters: {
                    $or: filters,
                  },
                },
                {
                  encodeValuesOnly: true, // prettify URL
                }
              );
              const response = await instance.get(`adaptations?${query}`, {
                cancelToken: cancelTokenSource.token,
              });
              const data = convertStrapiResponse<AdaptationProps>(
                response.data.data
              );
              const adaptationsData = data.map(
                (a: AdaptationProps): FlatAdaptationProps => ({
                  id: a.id,
                  actionId: a.adaptive_action.data.id,
                  actionLabel: a.adaptive_action.data.attributes.label,
                  practiceLabel: a.adaptive_practice.data.attributes.label,
                  practiceDescription:
                    a.adaptive_practice.data.attributes.description,
                  practiceCommentNegative:
                    a.adaptive_practice.data.attributes.comment_negative,
                  practiceCommentPositive:
                    a.adaptive_practice.data.attributes.comment_positive,
                  practiceId: a.adaptive_practice.data.id,
                  sectorId: a.sector.data.id,
                  sectorLabel: a.sector.data.attributes.label,
                  threatId: a.threat.data.id,
                })
              );

              setAdaptations(adaptationsData);

              const counted: number[] = [];

              if (adaptationsData.length) {
                adaptationsData.forEach((element: FlatAdaptationProps) => {
                  if (
                    !element.noAdaptation &&
                    typeof element.sectorId !== "undefined"
                  ) {
                    counted[element.sectorId] =
                      (counted[element.sectorId] || 0) + 1;
                  }
                });
                setSectorsCount(counted);
              } else {
                setSectorsCount([]);
              }
              cache.current[threatIdsStr] = {
                adaptation: adaptationsData,
                sectorsCount: counted,
              }; // set response in cache;

              setStatus("fetched");
            } catch (error) {
              console.error(error);
              setStatus("error");
            }
            setAction("done");
          }
        };
        fetchAdaptations();
      } else {
        setAdaptations([]);
        setSectorsCount([]);
        setStatus("init");
        setAction("done");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    if (matrix.length === 0 && adaptations.length) {
      setAdaptations([]);
      setSectorsCount([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrix]);

  return {
    adaptations,
    status,
    sectorsCount,
    setAction,
  };
};

export const useAdaptationsContext = () => {
  const adaptationsContext = useContext(AdaptationsContext);
  if (!AdaptationsContext) {
    throw new Error(
      "useAdaptationsContext must be used within the AdaptationsContext.Provider"
    );
  }
  return adaptationsContext;
};

export const AdaptationsProvider: FC = ({ children }) => (
  <AdaptationsContext.Provider value={useAdaptationsContextValue()}>
    {children}
  </AdaptationsContext.Provider>
);
