import React, { createContext, FC, useContext, useEffect } from "react";
import { useState } from "react";
import { useMatricesContext } from "../api/MatrixProvider";
import { FlatMatrixProps } from "../models/matrixModel";
import { useCommuneStore } from "./CommuneStore";

interface MatrixStoreProps {
  matrix: Array<FlatMatrixProps>;
  setMatrix?: React.Dispatch<React.SetStateAction<FlatMatrixProps[]>>;
}

const MatrixStore = createContext<MatrixStoreProps>({
  matrix: [],
});

export const useMatrixStore = () => {
  const matrixstore = useContext(MatrixStore);
  if (!matrixstore) {
    throw new Error(
      "useMatrixStore must be used within the MatrixStore.Provider"
    );
  }
  return matrixstore;
};

export const MatrixStoreProvider: FC = ({ children }) => {
  const [matrix, setMatrix] = useState<Array<FlatMatrixProps>>([]);
  const { matrices } = useMatricesContext();
  const { commune } = useCommuneStore();
  const value = {
    matrix: matrix,
    setMatrix: setMatrix,
  } as MatrixStoreProps;

  useEffect(() => {
    if (matrix.length) setMatrix([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrices, commune]);

  return <MatrixStore.Provider value={value}>{children}</MatrixStore.Provider>;
};
