import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";

import { FlatMatrixProps } from "../../shared/models/matrixModel";
import {
  FinancingSourceProps,
  FlatAdaptationBenefitValueProps,
  FlatAdaptationCostProps,
  FlatAdditionalAdaptationCostValueProps,
  NoAdaptationCostProps,
} from "../../shared/models/adaptationCostModel";
import { FlatAdaptationProps } from "../../shared/models/adaptationModel";
import { DisplayTable, Li, styles, TableProps, Ul } from "./stylesPDF";
import { useTranslation } from "react-i18next";
import { FlatHistoryProps } from "../../shared/models/historyModel";
import baner from "../../assets/images/baner_BW.png";

export interface BenefitListProps extends FlatAdaptationBenefitValueProps {
  cost: number;
}

export interface AdaptationCostsListProps
  extends FlatAdditionalAdaptationCostValueProps {
  cost: number;
}

export interface PdfDocumentProps {
  commune: String;
  history: Array<FlatHistoryProps>;
  matrix: Array<FlatMatrixProps>;
  adaptation: Array<FlatAdaptationProps>;
  adaptationCosts: Array<FlatAdaptationCostProps>;
  noAdaptations: Array<NoAdaptationCostProps>;
  benefitsList: Array<BenefitListProps>;
  adaptationCostsList: Array<AdaptationCostsListProps>;
  financingSource?: FinancingSourceProps;
  bcrBenefitsSum: number;
}

// Create Document Component
export const DocumentPdf: React.FC<PdfDocumentProps> = ({
  commune,
  history,
  matrix,
  adaptation,
  noAdaptations,
  adaptationCosts,
  benefitsList,
  adaptationCostsList,
  financingSource,
  bcrBenefitsSum,
}) => {
  const practiceCosts: Array<{ id: string; cost: number }> = [];
  const { t } = useTranslation();
  let practiceCostsSum = 0;

  const additionalAdaptationCostsWithoutValueList: Array<{
    label: String;
    id: number;
    count: number;
  }> = [];
  const adaptationBenefitsWithoutValueList: Array<{
    label: String;
    id: number;
    count: number;
  }> = [];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={baner} style={styles.image} />
        <View>
          <Text style={styles.h1}>
            <h1>{t("Costs and benefits of adaptation activities")}</h1>
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            {t(
              "The results of the analysis are illustrative and may help in the initial diagnosis of the needs related to the implementation of adaptation investments."
            )}
          </Text>
          <Text style={styles.paragraph}>
            {/* TODO translate */}
            Na kolejnych stronach przedstawiono wyniki analizy dla Państwa gminy
            w zależności od wybranych działań i praktyk adaptacyjnych. Na
            podstawie zebranych danych historycznych oszacowano jakie straty
            wystąpiły w Państwa gminie na przestrzeni ostatnich 20 lat. Na bazie
            analizy ryzyka (dostępnej na{" "}
            <a
              href="https://klimada2.ios.gov.pl/ryzyko-zmian-klimatu/"
              target="_blank"
              rel="noreferrer">
              https://klimada2.ios.gov.pl/ryzyko-zmian-klimatu/
            </a>
            ) wskazano jaki poziom ryzyka związanego ze zmianami klimatu może
            wystąpić w Państwa gminie w przyszłości. W wyniku przeprowadzonej
            symulacji oszacowano poglądowo jakie potencjalne korzyści są możliwe
            do uzyskania dzięki realizacji praktyk adaptacyjnych, przy
            założonych kosztach realizacji tych praktyk, w Państwa gminie.
          </Text>
        </View>
        <View>
          <Text style={styles.paragraph}>
            {t("Selected commune")}:{" "}
            <span style={{ fontWeight: "bold" }}>{commune}</span>
          </Text>
        </View>

        <View style={{ marginTop: 20 }}>
          <Text style={styles.h2}>
            <h2>{t("Risk analysis results")}:</h2>
          </Text>
        </View>

        <View>
          <Text style={styles.paragraph}>
            {t(
              "Selected threats from the list of threats identified for the unit"
            )}
            :
          </Text>
          <Ul>
            {matrix.map((m) => (
              <Li key={m.value}> - {m.label}</Li>
            ))}
          </Ul>
        </View>
        <View wrap={false}>
          <Text style={styles.paragraph}>{t("Sensitive sectors")}: </Text>
          <Ul>
            {!!adaptation.filter((a) => !!a.sectorLabel).length ? (
              [
                ...new Set(
                  adaptation
                    .filter((a) => !!a.sectorLabel)
                    .map((a) => a.sectorLabel)
                ),
              ].map((a) => <Li key={a}> - {a}</Li>)
            ) : (
              <Li> - {t("no adaptations selected")}</Li>
            )}
          </Ul>
        </View>
        <View wrap={false}>
          <Text style={styles.paragraph}>{t("Historical losses")}:</Text>
          <DisplayTable
            tables={[
              {
                headCells: [
                  {
                    id: "label",
                    label: t("Threat"),
                  },
                  {
                    id: "year",
                    label: t("Year"),
                    cellAlign: "center",
                  },
                  {
                    id: "cost",
                    label: t("Cost"),
                    cellAlign: "right",
                    formatCell: "currency",
                  },
                ],
                data: history,
              },
            ]}
          />
        </View>

        {!!noAdaptations.length && (
          <View wrap={false}>
            <Text style={styles.paragraph}>
              {t("The cost of non-adaptation")} (
              {t("possible losses in the 30-year analysis period [until 2050]")}
              ):
            </Text>
            <DisplayTable
              tables={[
                {
                  headCells: [
                    {
                      id: "threatLabel",
                      label: t("Threat"),
                    },
                    {
                      id: "cost",
                      label: t("Cost of non-adaptation"),
                      cellAlign: "right",
                      formatCell: "currency",
                    },
                  ],
                  data: noAdaptations,
                },
              ]}
            />
          </View>
        )}

        {!!adaptationCosts.length && (
          <View wrap={false}>
            <Text style={styles.paragraph}>
              {t("Selected adaptation activities and practices")}:
            </Text>
            <DisplayTable
              tables={[
                {
                  headCells: [
                    {
                      id: "actionLabel",
                      label: t("Adaptive action"),
                    },
                    {
                      id: "practiceLabel",
                      label: t("Selected adaptation practice"),
                    },
                    {
                      id: "practiceCost",
                      label: t("Estimated costs of adaptation practices"),
                      cellAlign: "right",
                      formatCell: "currency",
                    },
                  ],
                  data: adaptationCosts.map((a, index) => {
                    practiceCosts.push({
                      id: a.id,
                      cost: a.constIndicatiors.reduce(
                        (acc, curr) =>
                          acc + (curr.amount || 0) * (curr.price || 0),
                        0
                      ),
                    });
                    return {
                      ...a,
                      practiceCost:
                        practiceCosts[practiceCosts.length - 1].cost,
                    };
                  }),
                },
              ]}
            />
          </View>
        )}
        {!!financingSource && (
          <View wrap={false}>
            <Text style={styles.paragraph}>
              {t("Funding sources for adaptation practices")}:
            </Text>
            <DisplayTable
              tables={[
                {
                  headCells: [
                    {
                      id: "own",
                      label: t("Own funds"),
                      cellAlign: "right",
                      formatCell: "percent",
                    },
                    {
                      id: "subsidy",
                      label: t("Co-financing"),
                      cellAlign: "right",
                      formatCell: "percent",
                    },
                  ],
                  data: [financingSource],
                },
              ]}
            />
          </View>
        )}

        {adaptationCosts.length > 0 && (
          <>
            <View>
              {adaptationCosts.map((a, index) => {
                const additionalAdaptationCostsWithValue =
                  a.additionalAdaptationCosts.filter((aa) => !!Number(aa.cost));
                const additionalAdaptationCostsWithoutValue =
                  a.additionalAdaptationCosts.filter((aa) => !Number(aa.cost));
                const adaptationBenefitsWithValue = a.adaptationBenefits.filter(
                  (ab) => !!Number(ab.cost)
                );
                const adaptationBenefitsWithoutValue =
                  a.adaptationBenefits.filter((ab) => !Number(ab.cost));

                additionalAdaptationCostsWithoutValue.forEach((aa) => {
                  const adaptationExists =
                    additionalAdaptationCostsWithoutValueList.findIndex(
                      (a) => a.id === aa.id
                    );
                  if (adaptationExists === -1) {
                    additionalAdaptationCostsWithoutValueList.push({
                      label: aa.label,
                      id: aa.id,
                      count: 1,
                    });
                  } else {
                    additionalAdaptationCostsWithoutValueList[adaptationExists]
                      .count++;
                  }
                });
                adaptationBenefitsWithoutValue.forEach((ab) => {
                  const adaptationExists =
                    adaptationBenefitsWithoutValueList.findIndex(
                      (a) => a.id === ab.id
                    );
                  if (adaptationExists === -1) {
                    adaptationBenefitsWithoutValueList.push({
                      label: ab.label,
                      id: ab.id,
                      count: 1,
                    });
                  } else {
                    adaptationBenefitsWithoutValueList[adaptationExists]
                      .count++;
                  }
                });

                // const benefitsSum =
                //   adaptationBenefitsWithValue
                //     .map((ab) => ab.cost)
                //     .reduce(
                //       (acc, curr) => Number(acc || 0) + Number(curr || 0),
                //       0
                //     ) || 0;
                // const costsSum =
                //   additionalAdaptationCostsWithValue
                //     .map((aa) => aa.cost)
                //     .reduce(
                //       (acc, curr) => Number(acc || 0) + Number(curr || 0),
                //       0
                //     ) || 0;
                const practiceCost =
                  practiceCosts.find((p) => p.id === a.id)?.cost || 0;
                // const enpv =
                //   Number(benefitsSum) - Number(costsSum) - Number(practiceCost);
                const tables = [
                  {
                    headerLabel:
                      t("Selected adaptation practice") + " nr " + (index + 1),
                    headCells: [
                      {
                        id: "actionLabel",
                        label: t("Action name"),
                        boldHeader: true,
                      },
                    ],
                    data: [a],
                  },
                  {
                    headCells: [
                      {
                        id: "practiceLabel",
                        label: t("Practice name"),
                        boldHeader: true,
                      },
                    ],
                    data: [a],
                  },
                  {
                    headCells: [
                      {
                        id: "practiceDescription",
                        label: t("Practice description"),
                        optional: true,
                      },
                    ],
                    data: [a],
                  },
                  {
                    headCells: [
                      {
                        id: "label",
                        label: t("Benefits"),
                        boldHeader: true,
                      },
                      {
                        id: "description",
                        label: t("Description"),
                      },
                      {
                        id: "cost",
                        label: t("Value"),
                        cellAlign: "right",
                        formatCell: "currency",
                      },
                    ],
                    data: adaptationBenefitsWithValue,
                  },
                  {
                    headCells: [
                      {
                        id: "label",
                        label: t("Additional benefits"),
                        boldHeader: true,
                      },
                    ],
                    data: adaptationBenefitsWithoutValue,
                  },
                  {
                    headCells: [
                      {
                        id: "label",
                        label: t("Costs"),
                        boldHeader: true,
                      },
                      {
                        id: "cost",
                        label: t("Value"),
                        cellAlign: "right",
                        formatCell: "currency",
                      },
                    ],
                    data: [
                      {
                        cost: practiceCost,
                        label: t("Estimated cost of practice"),
                      },
                    ],
                  },
                ] as Array<
                  TableProps<
                    | FlatAdditionalAdaptationCostValueProps
                    | FlatAdaptationBenefitValueProps
                  >
                >;

                if (additionalAdaptationCostsWithValue?.length > 0) {
                  tables.push({
                    headCells: [
                      {
                        id: "label",
                        label: t("Other adaptation costs"),
                        boldHeader: true,
                      },
                      {
                        id: "description",
                        label: t("Description"),
                      },
                      {
                        id: "cost",
                        label: t("Value"),
                        cellAlign: "right",
                        formatCell: "currency",
                      },
                    ],
                    data: additionalAdaptationCostsWithValue,
                  });
                }

                if (additionalAdaptationCostsWithoutValue?.length > 0) {
                  tables.push({
                    hideHeadCells:
                      additionalAdaptationCostsWithValue.length > 0,
                    headCells: [
                      {
                        id: "label",
                        label: t("Other adaptation costs"),
                        boldHeader: true,
                      },
                    ],
                    data: additionalAdaptationCostsWithoutValue,
                  });
                }
                return (
                  <View key={a.id} style={{ paddingBottom: 10 }}>
                    {index === 0 && (
                      <Text style={styles.paragraph}>
                        {t("Details of selected adaptation practices")}:
                      </Text>
                    )}
                    <DisplayTable tables={tables} />
                  </View>
                );
              })}
            </View>
            <View wrap={false}>
              <Text style={styles.paragraph}>{t("Adaptation costs")}:</Text>
              <DisplayTable
                tables={[
                  {
                    headCells: [
                      {
                        id: "label",
                        label: t("Costs"),
                        boldHeader: true,
                      },
                      {
                        id: "cost",
                        label: t("Value"),
                        cellAlign: "right",
                        formatCell: "currency",
                      },
                    ],
                    data: [
                      {
                        cost: (() => {
                          practiceCostsSum = practiceCosts
                            .map((p) => p.cost)
                            .reduce(
                              (acc, curr) => Number(acc) + Number(curr),
                              0
                            );
                          return practiceCostsSum;
                        })(),
                        label: t("Estimated adaptation practices costs"),
                      },
                    ],
                  },
                  {
                    hideHeadCells: true,
                    headCells: [
                      {
                        id: "label",
                        label: t("Cost"),
                      },
                      {
                        id: "cost",
                        label: t("Value"),
                        cellAlign: "right",
                        formatCell: "currency",
                      },
                    ],
                    data: adaptationCostsList,
                  },
                  {
                    headerLabel: t("Other adaptation costs"),
                    hideRow:
                      additionalAdaptationCostsWithoutValueList.length === 0,
                    hideHeadCells: true,
                    headCells: [
                      {
                        id: "label",
                        label: t("Cost"),
                      },
                      {
                        id: "cost",
                        label: t("Value"),
                        formatCell: "box",
                      },
                    ],
                    data: additionalAdaptationCostsWithoutValueList.map(
                      (add) => ({
                        label: add.label,
                        cost: add.count / adaptationCosts.length,
                      })
                    ),
                  },
                ]}
                summaryRows={[
                  {
                    label: t("Sum"),
                    value:
                      adaptationCostsList
                        .map((b) => b.cost)
                        .reduce(
                          (acc, curr) => Number(acc || 0) + Number(curr),
                          0
                        ) + Number(practiceCostsSum),
                    format: "currency",
                    bold: true,
                  },
                  {
                    label: t("Commune expenses"),
                    value: (() => {
                      practiceCostsSum = practiceCosts
                        .map((p) => p.cost)
                        .reduce((acc, curr) => Number(acc) + Number(curr), 0);
                      return (
                        practiceCostsSum * ((financingSource?.own || 0) / 100)
                      );
                    })(),
                    format: "currency",
                  },
                ]}
              />
            </View>
            <View wrap={false}>
              <Text style={styles.paragraph}>{t("Adaptation benefits")}:</Text>
              <DisplayTable
                tables={[
                  {
                    headCells: [
                      {
                        id: "label",
                        label: t("Benefits"),
                        boldHeader: true,
                      },
                      {
                        id: "cost",
                        label: t("Value"),
                        cellAlign: "right",
                        formatCell: "currency",
                      },
                    ],
                    data: benefitsList,
                  },
                  {
                    headerLabel: t("Additional benefits"),
                    hideHeadCells: true,
                    headCells: [
                      {
                        id: "label",
                        label: t("Benefit"),
                      },
                      {
                        id: "cost",
                        label: t("Value"),
                        formatCell: "box",
                      },
                    ],
                    data: adaptationBenefitsWithoutValueList.map((add) => ({
                      label: add.label,
                      cost: add.count / adaptationCosts.length,
                    })),
                  },
                ]}
                summaryRows={
                  benefitsList.length
                    ? [
                        {
                          label: t("Sum"),
                          value: benefitsList
                            .map((b) => b.cost)
                            .reduce(
                              (acc, curr) => Number(acc || 0) + Number(curr),
                              0
                            ),
                          format: "currency",
                          bold: true,
                        },
                      ]
                    : undefined
                }
              />
            </View>
            <View wrap={false}>
              <Text style={styles.paragraph}>
                Współczynnik Korzyści / Koszty (BCR) porównuje korzyści
                odniesione z realizacji działań i praktyk adaptacyjnych w
                okresie analizy z kosztami ich realizacji. Jeśli wartość BCR{" "}
                {">"} 1, wówczas opłaca się realizować analizowane praktyki
                adaptacyjne, gdyż korzyści z ich realizacji będą wyższe niż
                koszty. Jeśli BCR {"<"} 1 wówczas koszty przewyższają korzyści.
                Jeśli występuje wiele dodatkowych korzyści, wtedy rekomenduje
                się przeprowadzenie szczegółowej analizy ekonomicznej i
                określenie luki w finansowaniu, aby móc ubiegać się o
                dofinansowanie dla ekonomicznie korzystnych i uzasadnionych
                projektów.
              </Text>
              <DisplayTable
                tables={[
                  {
                    hideHeadCells: true,
                    headCells: [
                      {
                        id: "label",
                        label: "BCR = korzyści/koszty",
                      },
                      {
                        id: "cost",
                        label: t("Value"),
                        cellAlign: "right",
                        formatCell: "number",
                      },
                    ],
                    data: [
                      {
                        cost: bcrBenefitsSum / practiceCostsSum,
                        label: "BCR",
                      },
                    ],
                  },
                ]}
                comments={[
                  "Jeśli wskaźnik BCR przyjmuje wartość 1 oznacza to, że korzyści z działań adaptacyjnych (liczone jako prawdopodobne uniknięte straty), są równe oszacowanym kosztom. Jeśli wskaźnik BCR wynosi 2 oznacza to, że korzyści z adaptacji są dwukrotnie wyższe niż wskazane koszty działań adaptacyjnych. Bardzo ważne jest podanie realistycznych kosztów, uwzględniających koszty kapitałowe (inwestycyjne) oraz koszty operacyjne (roczne koszty utrzymania) w okresie analizy 30 lat.",
                  "Uwaga: Proszę zwrócić uwagę na dodatkowe korzyści i pozostałe koszty adaptacji będące skutkiem realizacji działań, które nie zostały zmonetyzowane, a które powinny zostać wzięte pod uwagę przy podejmowaniu decyzji o ich realizacji.",
                ]}
              />
            </View>
          </>
        )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
