import {
  Card,
  CardContent,
  Grid,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { t } from "i18next";
import React, { FC } from "react";
import { NoAdaptationCostProps } from "../../shared/models/adaptationCostModel";
import { NumberFormater } from "../NumberFormater/NumberFormater";
import { useNoAdaptationsContext } from "../../shared/api/NoAdaptationProvider";

interface NoAdaptationCardProps {
  field: NoAdaptationCostProps;
  classes: ClassNameMap;
}

const NoAdaptationCard: FC<NoAdaptationCardProps> = ({ field, classes }) => {
  const { threats } = useNoAdaptationsContext();

  // console.log(field);
  return (
    <Card variant="outlined" square>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel shrink>
              {t(
                "I do not plan to implement adaptation activities and practices for a given threat"
              )}
            </InputLabel>
            <Typography className={classes.typography}>
              {field.threatLabel}
            </Typography>
          </Grid>
          {threats
            .filter((t) => t.id === field.threatId)
            .map((t) =>
              t.noAdaptationDesc.map((d) => (
                <Grid item container xs={12} key={d.sectorLabel}>
                  <Grid item md={3}></Grid>
                  <Grid item md={6}>
                    <Typography className={classes.typography}>
                      <strong>{d.sectorLabel}</strong>
                    </Typography>
                    <Typography className={classes.typography}>
                      {d.description}
                    </Typography>
                  </Grid>
                  <Grid item md={3}></Grid>
                </Grid>
              ))
            )}
          <Grid item xs={6} md={9}></Grid>
          {Number(field.cost) === 0 ? (
            <Grid item xs={6} md={3}>
              <Typography className={classes.typography} align="right">
                {t(
                  "The costs of non-adaptation are difficult to estimate on the basis of the provided statistics"
                )}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={6} md={3}>
              <InputLabel shrink>{field.noAdaptationDescription}</InputLabel>
              <Typography className={classes.typography} align="right">
                <NumberFormater value={field.cost} suffix=" zł" />
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NoAdaptationCard;
