import React from "react";
import clsx from "clsx";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
//import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { FlatAdaptationProps } from "../../shared/models/adaptationModel";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import { useAdaptationStore } from "../../shared/stores/AdaptationStore";
import { useSectorsContext } from "../../shared/api/SectorProvider";
import { ColoredRow } from "./ColoredRow";
import { useTranslation } from "react-i18next";

// function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

//type Order = "asc" | "desc";

// function getComparator<Key extends keyof any>(
//   order: Order,
//   orderBy: Key
// ): (
//   a: { [key in Key]: number | string },
//   b: { [key in Key]: number | string }
// ) => number {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
//   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

interface HeadCell {
  disablePadding?: boolean;
  id: keyof FlatAdaptationProps;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  // onRequestSort: (
  //   event: React.MouseEvent<unknown>,
  //   property: keyof FlatAdaptationProps
  // ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  //order: Order;
  //orderBy: string;
  rowCount: number;
  noAdaptationSelected: boolean;
}

interface AdaptationTableProps {
  rows: FlatAdaptationProps[];
  selected: FlatAdaptationProps[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    // classes,
    onSelectAllClick,
    //order,
    //orderBy,
    numSelected,
    rowCount,
    // onRequestSort,
    noAdaptationSelected,
  } = props;
  const { t } = useTranslation();
  // const createSortHandler =
  //   (property: keyof FlatAdaptationProps) =>
  //   (event: React.MouseEvent<unknown>) => {
  //     onRequestSort(event, property);
  //   };

  const headCells: HeadCell[] = [
    {
      id: "actionLabel",
      numeric: false,
      label: t("Adaptive action"),
    },
    {
      id: "practiceLabel",
      numeric: false,
      label: t("Adaptation practice"),
    },
  ];
  return (
    <TableHead>
      <ColoredRow sectorColor="transparent">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            //sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
        <TableCell align="right">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  indeterminate={
                    !noAdaptationSelected &&
                    numSelected > 0 &&
                    numSelected < rowCount - 1
                  }
                  checked={
                    !noAdaptationSelected &&
                    rowCount - 1 > 0 &&
                    numSelected === rowCount - 1
                  }
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": t("Check all adaptation practices"),
                  }}
                />
              }
              label={t("Choose")}
              labelPlacement="start"
            />
          </FormGroup>
        </TableCell>
      </ColoredRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  noAdaptationSelected: boolean;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, noAdaptationSelected } = props;
  const { t } = useTranslation();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div">
          {noAdaptationSelected
            ? t("No adaptation was selected")
            : t("Selected") +
              " " +
              numSelected.toString() +
              " " +
              (numSelected < 2
                ? t("adaptation")
                : numSelected < 5
                ? t("adaptations")
                : t("adaptations5plus"))}
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div">
          {t("Suggested actions and adaptation practices")}
        </Typography>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    cell: {
      paddingTop: "2rem",
    },
    container: {
      overflowX: "visible",
    },
  })
);

export const AdaptationTable: React.FC<AdaptationTableProps> = ({
  rows,
  selected,
}) => {
  const classes = useStyles();
  // const [order, setOrder] = React.useState<Order>("asc");
  // const [orderBy, setOrderBy] = React.useState<keyof FlatAdaptationProps>("id");
  const { sectors } = useSectorsContext();
  const { addAdaptation, removeAdaptation } = useAdaptationStore();

  // const handleRequestSort = (
  //   event: React.MouseEvent<unknown>,
  //   property: keyof FlatAdaptationProps
  // ) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && rows.length - 1 !== selected.length) {
      const newSelecteds = rows;
      const poped = newSelecteds.pop();
      poped && removeAdaptation([poped]);
      addAdaptation(newSelecteds);
      return;
    }
    removeAdaptation(rows);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    row: FlatAdaptationProps
  ) => {
    const selectedIndex = selected.findIndex((v) => v.id === row.id);
    if (selectedIndex === -1) {
      addAdaptation([row]);
    } else {
      removeAdaptation([row]);
    }
  };

  const isNoAdaptationSelected = !!selected.filter((s) => !!s.noAdaptation)
    .length;

  const isSelected = (row: FlatAdaptationProps) =>
    !!selected.filter((item) => item.id === row.id).length;

  const isDisabled = (row: FlatAdaptationProps) => {
    if (isNoAdaptationSelected) {
      return !row.noAdaptation;
    } else if (selected.length > 0) {
      return !!row.noAdaptation;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} square variant="outlined">
        <>
          <EnhancedTableToolbar
            numSelected={selected.length}
            noAdaptationSelected={isNoAdaptationSelected}
          />
          <TableContainer className={classes.container}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                //order={order}
                //orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                // onRequestSort={handleRequestSort}
                rowCount={rows.length}
                noAdaptationSelected={isNoAdaptationSelected}
              />
              <TableBody>
                {/* {stableSort(rows, getComparator(order, orderBy)).map( */}
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const isItemDisabled = isDisabled(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <ColoredRow
                      hover
                      onClick={(event) =>
                        !isItemDisabled && handleClick(event, row)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={labelId}
                      selected={isItemSelected}
                      sectorColor={
                        sectors.filter((s) => s.id === row.sectorId)[0]
                          ?.color || "transparent"
                      }
                      title={
                        sectors.filter((s) => s.id === row.sectorId)[0]
                          ?.label || ""
                      }>
                      <TableCell
                        className={
                          sectors.filter((s) => s.id === row.sectorId)[0]?.color
                            ? classes.cell
                            : " "
                        }
                        align="left">
                        {row.actionLabel}
                      </TableCell>
                      <TableCell id={labelId} component="th" scope="row">
                        {row.practiceLabel}
                      </TableCell>
                      <TableCell padding="checkbox" align="right">
                        <Checkbox
                          checked={isItemSelected}
                          disabled={isItemDisabled}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                    </ColoredRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </Paper>
    </div>
  );
};
