import axios from "axios";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CommuneProps } from "../models/communeModel";
import { convertStrapiResponse } from "../models/strapiResponseModel";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

type status = "init" | "loading" | "fetched" | "error";

interface CommunesContextProps {
  communes: Array<CommuneProps>;
  status: status;
}

const communesContextDefaultValue: CommunesContextProps = {
  communes: [],
  status: "init",
};

const CommunesContext = createContext<CommunesContextProps>(
  communesContextDefaultValue
);

const useCommunesContextValue = (): CommunesContextProps => {
  const [communes, setCommunes] = useState<Array<CommuneProps>>([]);
  const [status, setStatus] = useState<status>("init");
  const cache = useRef<CommuneProps[]>();

  useEffect(() => {
    if (status === "init") {
      const fetchCommunes = async () => {
        if (cache.current) {
          const data = cache.current;
          setCommunes(data);
          setStatus("fetched");
        } else {
          setStatus("loading");
          try {
            const response = await instance.get("communes");
            const data = convertStrapiResponse<CommuneProps>(response.data.data);
            setCommunes(data);
            setStatus("fetched");
          } catch (error) {
            console.error(error);
            setStatus("error");
          }
        }
      };
      fetchCommunes();
    }
  }, [status]);

  return {
    communes,
    status,
  };
};

export const useCommunesContext = () => {
  const communesContext = useContext(CommunesContext);
  if (!CommunesContext) {
    throw new Error(
      "useCommunesContext must be used within the CommunesContext.Provider"
    );
  }
  return communesContext;
};

export const CommunesProvider: FC = ({ children }) => {
  const { communes, status } = useCommunesContextValue();
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { communes, status } as CommunesContextProps;
  return (
    <CommunesContext.Provider value={value}>
      {children}
    </CommunesContext.Provider>
  );
};
