import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Svg,
  Rect,
  G,
  Font,
} from "@react-pdf/renderer";
import { t } from "i18next";
import acuminRegular from "../../assets/fonts/Acumin-RPro.otf";
import acuminBold from "../../assets/fonts/Acumin-BdPro.otf";
import acuminItalic from "../../assets/fonts/Acumin-ItPro.otf";
import acuminBoldItalic from "../../assets/fonts/Acumin-BdItPro.otf";

// Register font
Font.register({
  family: "Acumin",
  fonts: [
    { src: acuminRegular },
    { src: acuminBold, fontWeight: "bold" },
    { src: acuminItalic, fontStyle: "italic" },
    { src: acuminBoldItalic, fontWeight: "bold", fontStyle: "italic" },
  ],
});

Font.registerHyphenationCallback((word) => [word]);
// Create styles
export const styles = StyleSheet.create({
  image: {
    marginBottom: 30,
    marginTop: -30,
    marginHorizontal: 0,
  },
  page: {
    fontFamily: "Acumin",
    padding: "60 40",
    fontSize: 12,
    letterSpacing: 0.5,
    textAlign: "left",
  },
  h1: {
    textAlign: "center",
    marginBottom: 20,
    lineHeight: 1.2,
    fontSize: 16,
    fontWeight: "bold",
  },
  h2: {
    lineHeight: 1.2,
    marginBottom: 20,
    fontSize: 14,
  },
  h3: {
    lineHeight: 1.2,
    marginBottom: 20,
    fontSize: 12,
  },
  paragraph: {
    marginBottom: 10,
    fontSize: 12,
    lineHeight: 1.2,
  },
  comment: {
    marginBottom: 6,
    fontSize: 11,
    fontStyle: "italic",
    lineHeight: 1.2,
    marginLeft: 6,
  },
  table: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    borderTop: 1,
  },
  tableCell: {
    flexGrow: 1,
    padding: 5,
    borderColor: "#000000",
    borderLeft: 1,
    flexBasis: 1,
    borderStyle: "solid",
    fontFamily: "Acumin",
    fontSize: 9,
  },
  lastCell: {
    borderRight: 1,
  },
  lastRow: {
    borderBottom: 1,
  },
  ul: {
    marginLeft: 30,
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  li: {},
  bold: { fontWeight: "bold" },

  box: {
    width: 10,
    height: 10,
    border: "1 solid black",
  },
  filledBox: {
    backgroundColor: "black",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export const formatNumber = (
  number: number,
  options: Intl.NumberFormatOptions = {}
) => {
  return new Intl.NumberFormat("pl-PL", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...options,
  }).format(number);
};

export const Table: React.FC = (props) => (
  <View style={styles.table} {...props} wrap={false} />
);
export const Tr: React.FC<{ last?: boolean }> = ({ last = false, ...rest }) => (
  <View style={[styles.tableRow, last ? styles.lastRow : {}]} {...rest} />
);
export const Td: React.FC<{
  last?: boolean;
  align?: "left" | "right" | "center";
  bold?: boolean;
  text?: boolean;
}> = ({ last = false, align = "left", bold = false, text = true, ...rest }) => {
  return text ? (
    <Text
      style={[
        styles.tableCell,
        last ? styles.lastCell : {},
        { textAlign: align },
        bold ? styles.bold : {},
      ]}
      {...rest}
    />
  ) : (
    <View
      style={[
        styles.tableCell,
        last ? styles.lastCell : {},
        { textAlign: align },
        bold ? styles.bold : {},
      ]}
      {...rest}
    />
  );
};

export const Ul: React.FC = ({ children, ...rest }) => (
  <View style={styles.ul} {...rest}>
    {children}
  </View>
);
export const Li: React.FC = (props) => <Text style={styles.li} {...props} />;

export const Box: React.FC<{ percent: number }> = ({ percent }) => (
  <Svg style={{ width: 245, height: 20 }}>
    <G>
      <Rect width="245" height="20" x={0} y={0} stroke="#000" strokeWidth={1} />
      <Rect
        width={245 * percent}
        height="20"
        x={0}
        y={0}
        fill="#ccc"
        stroke="#000"
        strokeWidth={1}
      ></Rect>
    </G>
    <Text x={110} y={14} style={{ fontSize: 8, fontFamily: "Acumin" }}>
      {percent < 0.3334
        ? t("Low impact")
        : percent < 0.6667
        ? t("Medium impact")
        : t("High impact")}
    </Text>
  </Svg>
);

export interface HeadCell<T> {
  id: keyof T;
  label: string;
  boldHeader?: boolean;
  cellAlign?: "left" | "right" | "center";
  formatCell?: "number" | "currency" | "percent" | "box";
  optional?: boolean;
}

export interface TableProps<T> {
  headerLabel?: string;
  optionalHeaderLabel?: boolean;
  hideRow?: boolean;
  hideHeadCells?: boolean;
  headCells: Array<HeadCell<T>>;
  data: Array<T>;
}

export interface TablesProps<T> {
  tables: Array<TableProps<T>>;
  summaryRows?: Array<{
    label: string;
    value: number;
    bold?: boolean;
    format?: "currency" | "number" | "percent";
  }>;
  comments?: string[];
}

export function DisplayTable<T>({
  tables,
  summaryRows,
  comments,
}: TablesProps<T>): React.ReactElement {
  return (
    <View>
      <Table>
        {tables.map((table, i) => {
          const hideRows =
            table.data.length === 0 ||
            (table.headCells.length === 1 &&
              table.headCells[0].optional &&
              (table.data[0][table.headCells[0].id] === undefined ||
                (table.data[0][table.headCells[0].id] as unknown) === ""));
          return (
            <React.Fragment key={i}>
              {!table.hideRow &&
                !!table.headerLabel &&
                !(table.optionalHeaderLabel && hideRows) && (
                  <Tr key={i}>
                    <Td last bold>
                      {table.headerLabel}
                    </Td>
                  </Tr>
                )}
              {!hideRows && (
                <>
                  {!table.hideHeadCells && (
                    <Tr>
                      {table.headCells.map((headCell, index) => (
                        <Td
                          key={headCell.id.toString()}
                          bold={headCell.boldHeader}
                          last={index + 1 === table.headCells.length}
                        >
                          {headCell.label}
                        </Td>
                      ))}
                    </Tr>
                  )}
                  {table.data.map((row, index) => (
                    <Tr
                      key={index.toString()}
                      last={
                        i + 1 === tables.length &&
                        index + 1 === table.data.length &&
                        !summaryRows
                      }
                    >
                      {table.headCells.map((headCell, headIndex) =>
                        headCell.formatCell === "box" ? (
                          <Td
                            key={headCell.id.toString()}
                            align={headCell.cellAlign}
                            text={false}
                            last={headIndex + 1 === table.headCells.length}
                          >
                            <Box percent={Number(row[headCell.id])} />
                          </Td>
                        ) : (
                          <Td
                            key={headCell.id.toString()}
                            align={headCell.cellAlign}
                            text={true}
                            last={headIndex + 1 === table.headCells.length}
                          >
                            {headCell.formatCell !== undefined
                              ? formatNumber(Number(row[headCell.id])) +
                                (headCell.formatCell === "currency"
                                  ? " zł"
                                  : headCell.formatCell === "percent"
                                  ? " %"
                                  : "")
                              : row[headCell.id]}
                          </Td>
                        )
                      )}
                    </Tr>
                  ))}
                </>
              )}
            </React.Fragment>
          );
        })}
        {summaryRows?.map((summaryRow, index) => (
          <Tr last={summaryRows.length === index + 1} key={index}>
            <Td bold={summaryRow.bold}>{summaryRow.label}</Td>
            <Td last align="right">
              {summaryRow.format
                ? formatNumber(Number(summaryRow.value)) +
                  (summaryRow.format === "currency"
                    ? " zł"
                    : summaryRow.format === "percent"
                    ? " %"
                    : "")
                : summaryRow.value}
            </Td>
          </Tr>
        ))}
      </Table>
      {comments?.map((comment, index) => (
        <Text key={index.toString()} style={styles.comment}>
          {comment}
        </Text>
      ))}
    </View>
  );
}
