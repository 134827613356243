import React from "react";
import clsx from "clsx";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { useMatricesContext } from "../../shared/api/MatrixProvider";
import { Alert, Skeleton } from "@material-ui/lab";
import { FlatMatrixProps } from "../../shared/models/matrixModel";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import { useMatrixStore } from "../../shared/stores/MatrixStore";
import { useTranslation } from "react-i18next";
import { useSectorsContext } from "../../shared/api/SectorProvider";
import { theme } from "../../config/theme";
import { yellow } from "@material-ui/core/colors";
import LightTooltip from "../LightTooltip/LightTooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import { Pill } from "../SectorPills/Pill";

interface HeadCell {
  disablePadding?: boolean;
  isSector?: boolean;
  id: string;
  label: string;
  numeric: boolean;
  align?: "left" | "right" | "center";
}

const riskLegend = {
  "-1": {
    color: theme.palette.grey[200],
    label: "Does not concern",
    textColor: theme.palette.common.black,
  },
  "0": {
    color: "transparent",
    label: "Data not available",
    textColor: theme.palette.grey[300],
  },
  "1": {
    color: theme.palette.success.dark + "ee",
    label: "Very low",
    textColor: theme.palette.success.contrastText,
  },
  "2": {
    color: theme.palette.success.main + "ee",
    label: "Low",
    textColor: theme.palette.success.contrastText,
  },
  "3": {
    color: theme.palette.augmentColor(yellow).main + "ee",
    label: "Medium",
    textColor: theme.palette.augmentColor(yellow).contrastText,
  },
  "4": {
    color: theme.palette.warning.main + "ee",
    label: "High",
    textColor: theme.palette.warning.contrastText,
  },
  "5": {
    color: theme.palette.error.main + "ee",
    label: "Very high",
    textColor: theme.palette.error.contrastText,
  },
};

const useLegendStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: ({
        riskValue,
      }: {
        riskValue: keyof typeof riskLegend;
      }) => riskLegend[riskValue].color,
      color: ({ riskValue }: { riskValue: keyof typeof riskLegend }) =>
        riskLegend[riskValue].textColor,
    },
  })
);

const tooltipContent = (theme: Theme) => {
  return (
    <React.Fragment>
      <Typography variant="body2">
        Poziomy ryzyka:
        <List>
          <ListItem>
            <Pill bgColor={riskLegend[5].color} /> Czerwony – bardzo wysokie
            ryzyko{" "}
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <Pill bgColor={riskLegend[4].color} /> Pomarańczowy – wysokie ryzyko
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <Pill bgColor={riskLegend[3].color} /> Żółty – średnie ryzyko
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <Pill bgColor={riskLegend[2].color} /> Zielony – niskie ryzyko
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <Pill bgColor={riskLegend[1].color} /> Ciemnozielony – bardzo niskie
            ryzyko
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <Pill bgColor={riskLegend[0].color} /> Przezroczysty – brak danych
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <Pill bgColor={riskLegend[-1].color} /> Szary – ryzyko nie dotyczy
            sektora
          </ListItem>
        </List>
      </Typography>
    </React.Fragment>
  );
};

function RiskCell({ riskValue }: { riskValue: keyof typeof riskLegend }) {
  const classes = useLegendStyles({ riskValue });
  const { t } = useTranslation();
  return (
    <TableCell className={classes.root} align="center">
      <Typography variant="caption">
        {t(riskLegend[riskValue].label)}
      </Typography>
    </TableCell>
  );
}

let headCells: HeadCell[] = [];
interface MatrixTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function MatrixTableHead(props: MatrixTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || "right"}
            padding={headCell.disablePadding ? "none" : "default"}
            width={
              headCell.isSector
                ? Math.floor(100 / (headCells.length + 1)) - 1 + "%"
                : "initial"
            }
          >
            {headCell.isSector ? (
              <Typography variant="caption">{headCell.label}</Typography>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell align="right">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": t("Select all threats") }}
                />
              }
              label={t("Select")}
              labelPlacement="start"
            />
          </FormGroup>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

interface MatrixTableToolbarProps {
  numSelected: number;
}

const MatrixTableToolbar = (props: MatrixTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  const { t } = useTranslation();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {t("Selected")} {numSelected}
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="matrix_table_title"
          component="div"
        >
          {t("Risks")}
        </Typography>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export default function MatrixTable() {
  const classes = useStyles();
  const { matrices, status } = useMatricesContext();
  const { matrix: selected, setMatrix: setSelected } = useMatrixStore();
  const { t } = useTranslation();
  const { sectors } = useSectorsContext();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected && setSelected(matrices);
      return;
    }
    setSelected && setSelected([]);
  };

  const filteredSectors = sectors.filter((s) => s.is_matrix);

  headCells = [
    {
      id: "label",
      numeric: false,
      align: "left" as HeadCell["align"],
      label: `${t("Threat")} \\ ${t("Sector")}`,
    },
    ...filteredSectors.map((sector) => ({
      id: sector.label,
      numeric: false,
      isSector: true,
      label: sector.label,
      align: "center" as HeadCell["align"],
    })),
  ];
  const handleClick = (
    event: React.MouseEvent<unknown>,
    row: FlatMatrixProps
  ) => {
    const selectedIndex = selected.findIndex((v) => v.value === row.value);
    let newSelected: FlatMatrixProps[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected && setSelected(newSelected);
  };

  const isSelected = (row: FlatMatrixProps) =>
    !!selected.filter((item) => item.value === row.value).length;

  return (
    <div className={classes.root}>
      {status === "error" && (
        <Alert severity="error">
          {t("A threat list retrieval error has occurred")}
        </Alert>
      )}
      {(status === "loading" || status === "fetched") && (
        <>
          <Typography variant="h3" component="h2">
            Ryzyko w wybranych sektorach
          </Typography>
          <Typography paragraph>
            Poniżej zaprezentowano wyniki analizy ryzyka dla wybranej gminy.
            Istotne zagrożenia przedstawiono na tle wybranych sektorów.{" "}
            <LightTooltip title={tooltipContent(theme)} interactive>
              <span>Kolory</span>
            </LightTooltip>{" "}
            odzwierciedlają poziom ryzyka.
          </Typography>
          <Typography paragraph>
            Po zapoznaniu z wynikami analizy ryzyka dla Państwa gminy proszę
            wybrać zagrożenia, których będzie dotyczyć dalsza analiza.
          </Typography>
          <Paper className={classes.paper} square variant="outlined">
            <MatrixTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="matrix_table_title"
                size="medium"
                aria-label="matrix table"
              >
                {status === "fetched" && (
                  <MatrixTableHead
                    classes={classes}
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={matrices.length}
                  />
                )}
                <TableBody>
                  {status === "loading" &&
                    [...Array(5)].map((e, i) => (
                      <TableRow key={i}>
                        <TableCell colSpan={headCells.length + 1}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      </TableRow>
                    ))}

                  {status === "fetched" &&
                    matrices.map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `matrix-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={labelId}
                          selected={isItemSelected}
                        >
                          <TableCell component="th" id={labelId} scope="row">
                            {row.label}
                          </TableCell>
                          {filteredSectors.map((sector) => {
                            const riskValue =
                              row.risks.find((r) => r.sectorId === sector.id)
                                ?.riskValue || 0;
                            return (
                              <RiskCell
                                key={sector.id}
                                riskValue={
                                  riskValue.toString() as keyof typeof riskLegend
                                }
                              />
                            );
                          })}
                          <TableCell padding="checkbox" align="right">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </div>
  );
}
