import axios, { AxiosResponse } from "axios";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FlatAdaptationCostProps,
  CostIndicatorSpecProps,
  AdaptationBenefitValueProps,
  AdditionalAdaptationCostValueProps,
} from "../models/adaptationCostModel";
import { useAdaptationStore } from "../stores/AdaptationStore";
import { useCommuneStore } from "../stores/CommuneStore";
import { PracticeProps } from "../models/practiceModel";
import { FlatAdaptationProps } from "../models/adaptationModel";
const qs = require("qs");

const cancelTokenSource = axios.CancelToken.source();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

type Status = "init" | "loading" | "fetched" | "error";
type Action = "fetch" | "fetching" | "done";

interface AdaptationCostsContextProps {
  adaptationCosts: Array<FlatAdaptationCostProps>;
  status: Status;
  setAction?: (value: React.SetStateAction<Action>) => void;
}

const adaptationCostsContextDefaultValue: AdaptationCostsContextProps = {
  adaptationCosts: [],
  status: "init",
};

const AdaptationCostsContext = createContext<AdaptationCostsContextProps>(
  adaptationCostsContextDefaultValue
);

const useAdaptationCostsContextValue = (): AdaptationCostsContextProps => {
  const [adaptationCosts, setAdaptationCosts] = useState<
    Array<FlatAdaptationCostProps>
  >([]);
  const [status, setStatus] = useState<Status>("init");
  const [action, setAction] = useState<Action>("done");
  const [lastFetchId, setLastFetchId] = useState("");
  const { adaptation } = useAdaptationStore();
  const { commune } = useCommuneStore();
  const cache = useRef<{
    [key: string]: FlatAdaptationCostProps[];
  }>({});

  useEffect(() => {
    if (action === "fetch") {
      setAction("fetching");
      if (status === "loading") cancelTokenSource.cancel();

      if (adaptation.length && !!commune) {
        const adaptations = adaptation.filter((a) => !a.noAdaptation);
        const practiceIds = adaptations.map((a) => a.practiceId);
        const practiceIdStr = practiceIds.join(",");
        if (adaptations.length === 0) {
          setAdaptationCosts([]);
          setStatus("init");
          setAction("done");
          return;
        }
        setStatus("loading");
        const fetchAdaptationCosts = async () => {
          if (cache.current[practiceIdStr]) {
            if (lastFetchId !== practiceIdStr) {
              setAdaptationCosts(cache.current[practiceIdStr]);
              setLastFetchId(practiceIdStr);
            }
            setStatus("fetched");
          } else {
            try {
              const query = qs.stringify(
                {
                  populate: [
                    "add_adapt_cost_values.add_adapt_cost",
                    "adaptation_benefit_values.adaptation_benefit",
                    "cost_indicator_specs.cost_indicator",
                  ],
                  filters: {
                    id: {
                      $in: practiceIds,
                    },
                  },
                },
                {
                  encodeValuesOnly: true, // prettify URL
                }
              );
              const response: AxiosResponse<{ data: PracticeProps[] }> =
                await instance.get(`adaptive-practices?${query}`, {
                  cancelToken: cancelTokenSource.token,
                });

              const uniqueAdaptations = adaptations
                .filter(
                  (obj, index, arr) =>
                    index ===
                    arr.findIndex(
                      (t) =>
                        t.actionId === obj.actionId &&
                        t.practiceId === obj.practiceId
                    )
                )
                .sort(
                  (a, b) =>
                    Number(a.practiceLabel.split(" ")[0]) -
                    Number(b.practiceLabel.split(" ")[0])
                );

              const adaptationcostsData = uniqueAdaptations.map(
                (a: FlatAdaptationProps): FlatAdaptationCostProps => {
                  const practice = response.data.data.find((p) => {
                    return p.id === a.practiceId;
                  });

                  if (!practice) throw new Error("Practice not found");

                  return {
                    id: `${a.id}_${practice.id}`,
                    constIndicatiors:
                      practice.attributes.cost_indicator_specs.data.map(
                        (c: CostIndicatorSpecProps) => ({
                          id: c.id,
                          label:
                            c.attributes.cost_indicator.data.attributes.label,
                          description: c.attributes.description,
                        })
                      ),

                    adaptationId: a.id,
                    sectorId: a.sectorId,
                    threatId: a.threatId,
                    actionLabel: a.actionLabel,
                    practiceId: practice.id,
                    practiceLabel: a.practiceLabel,
                    practiceDescription: a.practiceDescription,
                    adaptationBenefits:
                      practice.attributes.adaptation_benefit_values.data.map(
                        (ab: AdaptationBenefitValueProps) => ({
                          id: ab.id,
                          benefitId: ab.attributes.adaptation_benefit.data.id,
                          label:
                            ab.attributes.adaptation_benefit.data.attributes
                              .label,
                          description:
                            ab.attributes.adaptation_benefit.data.attributes
                              .description,
                          cost: ab.attributes.cost,
                        })
                      ),

                    practiceCommentNegative:
                      practice.attributes.comment_negative,
                    practiceCommentPositive:
                      practice.attributes.comment_positive,
                    reductionFactor: practice.attributes.reduction_factor,
                    additionalAdaptationCosts:
                      practice.attributes.add_adapt_cost_values.data.map(
                        (ad: AdditionalAdaptationCostValueProps) => ({
                          id: ad.id,
                          additionalCostId:
                            ad.attributes.add_adapt_cost.data.id,
                          cost: ad.attributes.cost,
                          label:
                            ad.attributes.add_adapt_cost.data.attributes
                              .label || "",
                          description:
                            ad.attributes.add_adapt_cost.data.attributes
                              .description || "",
                        })
                      ),
                  };
                }
              );

              setAdaptationCosts(adaptationcostsData);

              cache.current[practiceIdStr] = adaptationcostsData;
              setLastFetchId("practiceIdStr");
              setStatus("fetched");
            } catch (error) {
              console.error(error);
              setStatus("error");
            }
            setAction("done");
          }
        };
        fetchAdaptationCosts();
      } else {
        setAdaptationCosts([]);
        setStatus("init");
        setAction("done");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    if (adaptation.length === 0 && adaptationCosts.length) {
      setAdaptationCosts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adaptation]);

  return {
    adaptationCosts,
    status,
    setAction,
  };
};

export const useAdaptationCostsContext = () => {
  const adaptationCostsContext = useContext(AdaptationCostsContext);
  if (!AdaptationCostsContext) {
    throw new Error(
      "useAdaptationCostsContext must be used within the AdaptationCostsContext.Provider"
    );
  }
  return adaptationCostsContext;
};

export const AdaptationCostsProvider: FC = ({ children }) => (
  <AdaptationCostsContext.Provider value={useAdaptationCostsContextValue()}>
    {children}
  </AdaptationCostsContext.Provider>
);
