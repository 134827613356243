import {
  Card,
  CardContent,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { t } from "i18next";
import React, { FC } from "react";
import {
  Control,
  Controller,
  FieldNamesMarkedBoolean,
  UseFormGetValues,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { StoreAdaptationCostProps } from "../../shared/models/adaptationCostModel";
import { NumberFormater } from "../NumberFormater/NumberFormater";

interface FinancingSourceCardProps {
  control: Control<StoreAdaptationCostProps>;
  dirtyFields: FieldNamesMarkedBoolean<any>;
  getValues: UseFormGetValues<StoreAdaptationCostProps>;
  setValue: UseFormSetValue<StoreAdaptationCostProps>;
  classes: ClassNameMap;
}

const PercentageSum = ({
  control,
  getValues,
  classes,
}: {
  control: Control<StoreAdaptationCostProps>;
  getValues: UseFormGetValues<StoreAdaptationCostProps>;
  classes: ClassNameMap;
}) => {
  const ownValue = useWatch({
    name: "financingSource.own",
    control,
  });
  const subsidyValue = getValues("financingSource.subsidy");
  const total = Number(ownValue || 0) + Number(subsidyValue || 0);
  return (
    <Grid item xs={12}>
      <Typography className={classes.typography}>
        <NumberFormater value={total} suffix=" %" />
      </Typography>
    </Grid>
  );
};

const CoFinancing = ({
  control,
  setValue,
  dirtyFields,
  classes,
}: {
  control: Control<StoreAdaptationCostProps>;
  dirtyFields: FieldNamesMarkedBoolean<any>;
  setValue: UseFormSetValue<StoreAdaptationCostProps>;
  classes: ClassNameMap;
}) => {
  const ownValue = useWatch({
    name: "financingSource.own",
    control,
  });
  let value = 0;
  if (dirtyFields.financingSource?.own && Number(ownValue || 0) <= 100) {
    value = 100 - Number(ownValue || 0);
    setValue("financingSource.subsidy", value);
  }
  return (
    <Grid item xs={12}>
      <Typography className={classes.typography}>
        <NumberFormater value={value} suffix=" %" />
      </Typography>
    </Grid>
  );
};

const FinancingSourceCard: FC<FinancingSourceCardProps> = ({
  control,
  dirtyFields,
  setValue,
  getValues,
  classes,
}) => {
  return (
    <Card variant="outlined" square>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h5">
              {t("Financing source")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`financingSource.own`}
              control={control}
              rules={{
                required: t("The field is required").toString(),
                max: {
                  value: 100,
                  message: t("Maximum 100%").toString(),
                },
                validate: {
                  maxPercentage: (v) =>
                    dirtyFields.financingSource?.subsidy
                      ? Number(getValues("financingSource.subsidy")) +
                          Number(v) ===
                        100
                      : true,
                },
                deps: dirtyFields.financingSource?.subsidy
                  ? ["financingSource.subsidy"]
                  : undefined,
              }}
              render={({
                field: { ref, ...rest },
                fieldState: { error, invalid },
              }) => {
                return (
                  <TextField
                    {...rest}
                    error={invalid}
                    label={t("Own funds")}
                    inputRef={ref}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormater as any,
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    helperText={error?.message}
                  />
                );
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Controller
              name={`financingSource.subsidy`}
              control={control}
              rules={{
                required: t("The field is required").toString(),
                max: {
                  value: 100,
                  message: t("Maximum 100%").toString(),
                },
                validate: {
                  maxPercentage: (v) =>
                    dirtyFields.financingSource?.own
                      ? Number(getValues("financingSource.own")) + Number(v) ===
                        100
                      : true,
                },
                deps: dirtyFields.financingSource?.own
                  ? ["financingSource.own"]
                  : undefined,
              }}
              render={({
                field: { ref, ...rest },
                fieldState: { error, invalid },
              }) => {
                return (
                  <TextField
                    {...rest}
                    error={invalid}
                    label={t("Co-financing")}
                    inputRef={ref}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormater as any,
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    helperText={error?.message}
                  />
                );
              }}
            />
          </Grid> */}
          <Grid item xs={12}>
            <InputLabel shrink>{t("Co-financing")}:</InputLabel>
            <CoFinancing
              control={control}
              classes={classes}
              setValue={setValue}
              dirtyFields={dirtyFields}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel shrink>{t("Sum")}:</InputLabel>
            <PercentageSum
              control={control}
              classes={classes}
              getValues={getValues}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FinancingSourceCard;
