import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { FlatMatrixProps } from "../../shared/models/matrixModel";
import { useMatrixStore } from "../../shared/stores/MatrixStore";
import { useAdaptationsContext } from "../../shared/api/AdaptationProvider";
import { useTranslation } from "react-i18next";
import { useMatricesContext } from "../../shared/api/MatrixProvider";

interface Option {
  value: any;
  label: string;
}

export const ThreatSelect: FC = () => {
  const { matrices, status } = useMatricesContext();
  const { matrix: selected, setMatrix: setSelected } = useMatrixStore();
  const { setAction } = useAdaptationsContext();
  const { t } = useTranslation();
  return (
    <Autocomplete
      id="threat-select"
      options={matrices as Option[]}
      loading={status !== "fetched"}
      multiple
      value={selected}
      onChange={(e, value) => {
        setSelected && setSelected(value as FlatMatrixProps[]);
        setAction && setAction("fetch");
      }}
      getOptionSelected={(o, v) => {
        return o.value === v.value;
      }}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label={t("Threat")} variant="outlined" />
      )}
      disabled={!matrices.length}
    />
  );
};
