import React, { createContext, FC, useContext } from "react";
import { useState } from "react";
import { CommuneProps } from "../models/communeModel";

interface CommuneStoreProps {
  commune: CommuneProps | null;
  setCommune: React.Dispatch<React.SetStateAction<CommuneProps> | null> | null;
}

const CommuneStore = createContext<CommuneStoreProps>({
  commune: null,
  setCommune: null,
});

export const useCommuneStore = () => {
  const communeStore = useContext(CommuneStore);
  if (!communeStore) {
    throw new Error(
      "useCommuneStore must be used within the CommuneStore.Provider"
    );
  }
  return communeStore;
};

export const CommuneStoreProvider: FC = ({ children }) => {
  const [commune, setCommune] = useState<CommuneProps | null>();
  const value = {
    commune: commune,
    setCommune: setCommune,
  } as CommuneStoreProps;
  return (
    <CommuneStore.Provider value={value}>{children}</CommuneStore.Provider>
  );
};
