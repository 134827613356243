import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./config/theme";
import { makeStyles, Paper, Container } from "@material-ui/core";
import { History } from "./pages/History";
import { CommuneStoreProvider } from "./shared/stores/CommuneStore";
import { HistoriesProvider } from "./shared/api/HistoryProvider";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
} from "react-router-dom";
import { CreateReport } from "./pages/CreateReport";
import { CommunesProvider } from "./shared/api/CommuneProvider";
import { SectorsProvider } from "./shared/api/SectorProvider";
import { AdaptationsProvider } from "./shared/api/AdaptationProvider";
import { AdaptationStoreProvider } from "./shared/stores/AdaptationStore";
import { AdaptationCostsProvider } from "./shared/api/AdaptationCostProvider";
import { AdaptationCostStoreProvider } from "./shared/stores/AdaptationCostStore";
import { NoAdaptationsProvider } from "./shared/api/NoAdaptationProvider";
// import { OptionsMenu } from "./components/OptionsMenu/OptionsMenu";
import { MatricesProvider } from "./shared/api/MatrixProvider";
import { MatrixStoreProvider } from "./shared/stores/MatrixStore";
import { BcrBenefitsProvider } from "./shared/api/BrcBenefitsProvider";

const useStyles = makeStyles({
  paper: {
    padding: theme.spacing(5),
    minHeight: "100vh",
    boxSizing: "border-box",
    position: "relative",
    boxShadow: "0 0 4px #e5e5e5",
  },
  container: {
    padding: 0,
  },
});

function App() {
  const classes = useStyles();
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SectorsProvider>
          <CommunesProvider>
            <CommuneStoreProvider>
              <HistoriesProvider>
                <Container
                  maxWidth="lg"
                  classes={{
                    root: classes.container,
                  }}>
                  <Paper className={classes.paper} square variant="outlined">
                    {/* <OptionsMenu /> */}
                    <MatricesProvider>
                      <MatrixStoreProvider>
                        <AdaptationsProvider>
                          <AdaptationStoreProvider>
                            <AdaptationCostsProvider>
                              <NoAdaptationsProvider>
                                <AdaptationCostStoreProvider>
                                  <BcrBenefitsProvider>
                                    <Switch>
                                      <ConditionalRoute path="/create-report">
                                        <CreateReport />
                                      </ConditionalRoute>
                                      <Route path="/">
                                        <History />
                                      </Route>
                                    </Switch>
                                  </BcrBenefitsProvider>
                                </AdaptationCostStoreProvider>
                              </NoAdaptationsProvider>
                            </AdaptationCostsProvider>
                          </AdaptationStoreProvider>
                        </AdaptationsProvider>
                      </MatrixStoreProvider>
                    </MatricesProvider>
                  </Paper>
                </Container>
              </HistoriesProvider>
            </CommuneStoreProvider>
          </CommunesProvider>
        </SectorsProvider>
      </ThemeProvider>
    </Router>
  );
}

const ConditionalRoute = ({ children, ...rest }: RouteProps) => {
  // const { history } = useHistoryStore();
  return (
    <Route
      {...rest}
      render={
        ({ location }) =>
          // history.length ? (
          children
        // ) : (
        //   <Redirect
        //     to={{
        //       pathname: "/",
        //     }}
        //   />
        // )
      }
    />
  );
};

export default App;
